import Card from '../../common/Card';
import Choice from '../../common/Choice';
import { useEffect, useState } from 'react';
import Label from '../../common/Label';
import Dropzone from '../../common/Dropzone';
import Button from '../../common/Button';
import ModalProhibited from '../../layout/ITHeadquarters/ModalProhibited';
import Modal from '../../common/Modal';
import { useDispatch } from 'react-redux';
import { setToastsError, setToastsSuccess } from '../../../redux/actions/common';
import { useTranslation } from 'react-i18next';
import { getExceptionItemTable, getProhibitedItemTable } from '../../tables/table';
import EditTable from '../../tables/EditTable';
import { Workbook } from 'exceljs';
import { ExceptionControllerService, ProhibitedControllerService } from '../../../services';
import { setLoader } from '../../../redux/actions/api';
import apiErrorHandler from '../../../api/apiErrorHandler';
import { formatTimestamp } from '../../../utils/constants/date';
import { clearErrors } from '../../../redux/actions/error';
import { useLocation } from 'react-router-dom';

const DEFAULT_STATE = {
    file: '',
    selectedLowestPriceType: 'COMPETITOR',
    isEditShowModal: false,
    isImportErrorModal: false,
    isCheckFile: false,
    isCheckShowTable: false,
    requestData: [],
    errors: [],
};

const ProhibitedItemImport = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        dispatch(clearErrors());
    }, [dispatch, location]);

    const { isException } = props;

    const [state, setState] = useState(DEFAULT_STATE);

    const { selectedLowestPriceType, file, isEditShowModal, isImportErrorModal, isCheckFile, requestData, errors } = state;

    const [formData, setFormData] = useState(null);

    const handleEditClick = (index, value) => {
        setFormData(value);
        handleChangeState('isEditShowModal', true);
    };

    const handleDeleteClick = (index) => {
        const newRequestData = requestData.filter((item, i) => i !== index);
        handleChangeState('requestData', newRequestData);
    };

    const table = isException ? getExceptionItemTable(t, handleDeleteClick) : getProhibitedItemTable(handleEditClick);

    const handleChangeState = (field, value) => {
        if (field === 'file') {
            if (value) {
                setState((prevState) => ({
                    ...prevState,
                    [field]: value,
                    isCheckFile: true,
                }));
            }
            setState((prevState) => ({
                ...prevState,
                isCheckFile: false,
            }));
        }
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleCloseEditColumn = () => {
        setState((prevState) => ({
            ...prevState,
            isEditShowModal: false,
        }));
    };

    const handleCloseImportErrorModal = () => {
        setState((prevState) => ({
            ...prevState,
            isImportErrorModal: false,
        }));
    };

    const handleSubmitEditFormData = () => {
        const newRequestData = requestData.map((item) => {
            if (item.jan === formData.jan) {
                return formData;
            }
            return item;
        });
        handleChangeState('requestData', newRequestData);
        handleChangeState('isEditShowModal', false);
    };

    const handleImportData = async () => {
        let isValid = false;
        isValid = await validateSubmitForm(isValid);
        if (isValid) {
            return;
        }

        if (file) {
            dispatch(setLoader(true));
            dispatch(clearErrors());
            const workbook = new Workbook();
            const buffer = await file.arrayBuffer();
            await workbook.xlsx.load(buffer);
            const requestData = [];
            const jans = [];
            const errorMessages = [];

            workbook.eachSheet((sheet, id) => {
                if (id === 1) {
                    sheet.eachRow((row, rowNumber) => {
                        if (rowNumber > 1) {
                            if (!row.values[1]) {
                                errorMessages.push(`A${rowNumber}行JANの値は必須です。`);
                            }
                            if (!row.values[2]) {
                                errorMessages.push(`B${rowNumber}行禁止の値は必須です。`);
                            }
                            if (row.values[1] && row.values[2]) {
                                requestData.push({
                                    jan: row.values[1],
                                    deleteFlag: row.values[2] === '禁止', // 禁止商品でのみ使用
                                    lowerLimit: row.values[3] ? row.values[3] : null, // 禁止商品でのみ使用
                                    startDate: row.values[4] ? formatTimestamp(row.values[4]) : null, // 禁止商品でのみ使用
                                    lowestPriceType: selectedLowestPriceType, // 禁止商品でのみ使用
                                });
                                jans.push(row.values[1]);
                            }
                        }
                    });
                }
            });

            if (errorMessages.length > 0) {
                handleChangeState('isImportErrorModal', true);
                handleChangeState('errors', errorMessages);
                dispatch(setLoader(false));
                return;
            }

            ProhibitedControllerService.getProhibitedItemsInfo(jans)
                .then((response) => {
                    if (response.data) {
                        const data = response.data;
                        const newFormData = requestData.map((item) => {
                            const itemData = data.find((dataItem) => dataItem.jan.toString() === item.jan.toString());
                            if (itemData) {
                                return {
                                    ...itemData,
                                    ...item,
                                };
                            }
                            return item;
                        });
                        handleChangeState('requestData', newFormData);
                    }
                })
                .catch((error) => {
                    apiErrorHandler(dispatch, error, t('message.error.import'));
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    const validateSubmitForm = (isValid) => {
        return new Promise((resolve) => {
            if (!file) {
                isValid = true;
                setState((prevState) => ({
                    ...prevState,
                    isCheckFile: true,
                }));
            }

            resolve(isValid);
        });
    };

    const handleClearForm = () => {
        setState(DEFAULT_STATE);
    };

    const handleSubmit = () => {
        dispatch(setLoader(true));
        dispatch(clearErrors());
        const request = isException
            ? ExceptionControllerService.upsertExceptionItems({
                  jans: requestData.map((item) => item.jan),
                  lowestPriceType: selectedLowestPriceType,
                  deleteFlag: false,
              })
            : ProhibitedControllerService.upsertProhibitedItems({
                  data: requestData,
              });
        request
            .then(() => {
                dispatch(setToastsSuccess('登録が完了しました。'));
                setState(DEFAULT_STATE);
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error, t('message.error.registration'));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    return (
        <div className="okc-container prohibited-products-2nd">
            <div className="okc-inner">
                <div className="okc-title c-slide-top">{t('prohibited_product_import.upload_zone.label')}</div>
                <div className="okc-card fade-in">
                    <Card type="modal_card">
                        <div className="row okc-card__row mb-0">
                            <Label
                                type="column"
                                className="col-lg-2 mt-lg-2 align-self-start"
                                text={t('prohibited_product_import.upload_zone.selling_price_type')}
                            />
                            <div className="col-lg-10 competitive-store__radios">
                                <div className="row m-0 competitive-store__row js-okc-validate">
                                    <Choice
                                        type="radio"
                                        column="col-xl-3 col-lg-4 col-md-5"
                                        onChange={(value) => handleChangeState('selectedLowestPriceType', value[0])}
                                        data={[
                                            {
                                                id: 'selling-price-type-competitive',
                                                value: 'COMPETITOR',
                                                label: t('prohibited_product_list.search_form.selling_price_type.competitive'),
                                            },
                                            {
                                                id: 'selling-price-type-freshness',
                                                value: 'FRESHNESS',
                                                label: t('prohibited_product_list.search_form.selling_price_type.freshness'),
                                            },
                                        ]}
                                        value={selectedLowestPriceType}
                                        checked={selectedLowestPriceType}
                                    />
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="okc-input-file c-slide-top">
                    <div className="okc-upload">
                        <Dropzone file={file} onChange={(value) => handleChangeState('file', value)} error={isCheckFile} />
                        {isCheckFile && !file && <p className="okc-error mt-2 mb-0">{t('message.error.please.select.file')}</p>}
                    </div>
                </div>
                <div className="d-flex justify-content-end c-slide-top mb-5">
                    <Button className="btn btn--primary prohibited-products-2nd__submit" onClick={handleImportData}>
                        <span className="btn-text">{t('prohibited_product_import.upload_zone.button.import')}</span>
                    </Button>
                    <Button className="btn btn--secondary ml-2 prohibited-products-2nd__clear" onClick={handleClearForm}>
                        <span className="btn-text">{t('prohibited_product_import.upload_zone.button.clear')}</span>
                    </Button>
                </div>
                {requestData?.length > 0 && (
                    <>
                        <EditTable
                            headerTitle={t('prohibited_product_import.result_table.head.title')}
                            heads={table.heads}
                            body={table.body}
                            dataTable={requestData}
                        />
                        <div className="d-flex c-slide-top mb-5" style={{ marginTop: '50px' }}>
                            <Button className="btn btn--primary btn-block" id="register-btn" onClick={handleSubmit}>
                                <span className="btn-text">{t('exception_product_import.button.complete')}</span>
                            </Button>
                        </div>
                    </>
                )}
                <Modal
                    size="xl"
                    isShow={isEditShowModal}
                    onClose={handleCloseEditColumn}
                    isShowIconClose="true"
                    title={t('exception_product_import.edit_modal.title')}
                    btnList={[
                        {
                            name: t('exception_product_import.button.renew'),
                            className: 'btn--primary',
                            onClick: () => handleSubmitEditFormData(),
                            disabled: false,
                        },
                    ]}
                >
                    <ModalProhibited formData={formData} setFormData={setFormData} />
                </Modal>
                <Modal
                    size="sm"
                    onClose={handleCloseImportErrorModal}
                    isShowIconClose="true"
                    title="アップロードエラー"
                    isShow={isImportErrorModal}
                >
                    <div class="alert ok-alert alert-danger" role="alert">
                        アップロードに失敗しました。下記のエラーを確認して下さい。
                    </div>
                    <div class="upload-confirm__error">
                        <p className="text-break">
                            {errors.map((error) => (
                                <>
                                    {error}
                                    <br />
                                </>
                            ))}
                        </p>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default ProhibitedItemImport;
