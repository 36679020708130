import { CLEAR_ERRORS, SET_TYPE_ERROR } from '../actions/type';

const initialState = {
    errors: [],
};

const errorReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TYPE_ERROR:
            if (state.errors.includes(action.message)) {
                return state;
            }
            return {
                ...state,
                errors: [...state.errors, action.message],
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                errors: [],
            };

        default:
            return state;
    }
};

export { errorReducer };
