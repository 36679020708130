/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../common/Input';
import Card from '../../common/Card';
import Label from '../../common/Label';
import Choice from '../../common/Choice';
import SelectSearch from '../../common/SelectSearch';
import Pagination from '../../common/Pagination';
import Button from '../../common/Button';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../common/Modal';
import CardPriceSearch from './CardPriceSearch';
import '../../../assets/css/pages/stores/price-search.css';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    fetchDivisions,
    fetchExpandSalesFloors,
    fetchItemCategories,
    fetchPackingCategories,
    fetchSaleTypes,
    setLoader,
} from '../../../redux/actions/api';
import { setToastsError, setToastsSuccess } from '../../../redux/actions/common';
import { STOP_PURCHASE_FLAG } from '../../../utils/constants/string';
import { ChangeRequestCompetitorCreateRequest, ChangeRequestControllerService, PriceSearchControllerService } from '../../../services';
import SelectTable from '../../tables/SelectTable';
import ViewTable from '../../tables/ViewTable';
import { getCompetitorRequestTable, getFreshnessRequestTable, getSearchTable } from '../../tables/table';
import env from '../../../configs/env';
import { discountValidation, limitOverValidation, prohibitedValidation, validateFormInput } from '../../../utils/helpers/validation';
import { setIsContinue } from '../../../redux/actions/modal';
import { formattedDate } from '../../../utils/helpers/date';
import apiErrorHandler from '../../../api/apiErrorHandler';
import { getAnalytics, setUserProperties } from 'firebase/analytics';
import { clearErrors } from '../../../redux/actions/error';

const DEFAULT_STATE = {
    selectedRows: [],
    requestData: [],

    itemName: '',
    jan: '',
    lines: [],
    classes: [],
    subclasses: [],
    selectedDivision: null,
    selectedDepartment: null,
    selectedLine: null,
    selectedClass: null,
    selectedSubclass: null,
    selectedItemCategory: 'ALL',
    selectedStopPurchaseFlag: '0',
    otherSpecialReports: '',

    page: 1,
    limit: 20,
    apiResponse: null,
    isShowTableMaster: false,
    isShowModalRegister: false,
};

const DEFAULT_FORM_DATA = {
    expandSalesFloor: ChangeRequestCompetitorCreateRequest.expandSalesFloor.REGULAR,
    discountedRequestSellingPrice: '',
    packingCategory: ChangeRequestCompetitorCreateRequest.packingCategory.SINGLE_ITEM,
    saleType: ChangeRequestCompetitorCreateRequest.saleType.MONTHLY,
    salesStartDate: '',
    salesEndDate: '',
    otherSpecialReports: '',

    freshnessReason: '賞味期限間近',
    reportFlag: false,
};

const PriceSearch = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useNavigate();
    const location = useLocation();

    const sort = useSelector((state) => state.sort);

    const constants = useSelector((state) => state.constants);
    const { divisions, itemCategories, expandSalesFloors, packingCategories, saleTypes } = constants;

    const storage = useSelector((state) => state.storage);
    const {
        isCompetitor,
        isFreshness,
        isHeadquarter,
        changeRequestType,
        requesterName,
        selectedCompetitorStoreCode,
        selectedRegistrationMethod,
        selectedSource,
        selectedDepartments,
    } = storage;

    const modal = useSelector((state) => state.modal);
    const { isContinue } = modal;

    const previousPageData = {
        competitorStoreCode: selectedCompetitorStoreCode,
        requesterName: requesterName,
        source: selectedSource,
        registrationMethod: 'FORM', // この画面から登録する場合はFORM
    };

    const isSelectExcel = selectedRegistrationMethod === 'EXCEL';

    const table = getSearchTable(t, isCompetitor);
    const tableSelect = getSearchTable(t, isCompetitor);
    const tableRequest = isFreshness
        ? getFreshnessRequestTable()
        : getCompetitorRequestTable(t, expandSalesFloors, packingCategories, saleTypes);

    const [state, setState] = useState(DEFAULT_STATE);

    const {
        selectedRows,
        requestData,

        itemName,
        jan,
        lines,
        classes,
        subclasses,
        selectedDivision,
        selectedLine,
        selectedClass,
        selectedSubclass,
        selectedItemCategory,
        selectedStopPurchaseFlag,

        page,
        limit,
        apiResponse,
        isShowTableMaster,
        isShowModalRegister,
    } = state;

    const [formData, setFormData] = useState({
        ...DEFAULT_FORM_DATA,
        ...previousPageData,
    });

    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const isHiddenButtonCompletion = isSelectExcel || isHeadquarter;

    useEffect(() => {
        dispatch(clearErrors());
        dispatch(fetchDivisions());
        dispatch(fetchItemCategories());
        dispatch(fetchExpandSalesFloors());
        dispatch(fetchPackingCategories());
        dispatch(fetchSaleTypes());

        return () => {
            setState(DEFAULT_STATE);
        };
    }, [dispatch, location]);

    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false);
            return;
        }
        handleSubmitForm();
    }, [page, sort]);

    useEffect(() => {
        const filteredDivisions = divisions?.filter((item) => {
            return (
                selectedDepartments?.includes(item.departmentCode.toString()) &&
                item.lineCode > 0 &&
                item.classCode === 0 &&
                item.subclassCode === 0
            );
        });

        const lines = filteredDivisions.map((item) => {
            return {
                ...item,
                value: item.lineCode,
                label: item.divisionName,
            };
        });

        setState((prevState) => ({
            ...prevState,
            filteredDivisions,
            lines,
            selectedLine: null,
            selectedClass: null,
            selectedSubclass: null,
        }));
    }, [divisions, selectedDepartments]);

    useEffect(() => {
        const filteredDivisions = divisions?.filter((item) => {
            return (
                selectedDepartments?.includes(item.departmentCode.toString()) &&
                item.lineCode === selectedLine &&
                item.classCode > 0 &&
                item.subclassCode === 0
            );
        });

        const classes = filteredDivisions.map((item) => {
            return {
                ...item,
                value: item.classCode,
                label: item.divisionName,
            };
        });

        setState((prevState) => ({
            ...prevState,
            filteredDivisions,
            classes,
            selectedClass: null,
            selectedSubclass: null,
        }));
    }, [divisions, lines, selectedDepartments, selectedLine]);

    useEffect(() => {
        const filteredDivisions = divisions?.filter((item) => {
            return (
                selectedDepartments?.includes(item.departmentCode.toString()) &&
                item.lineCode === selectedLine &&
                item.classCode === selectedClass &&
                item.subclassCode > 0
            );
        });

        const subclasses = filteredDivisions.map((item) => {
            return {
                ...item,
                value: item.subclassCode,
                label: item.divisionName,
            };
        });

        setState((prevState) => ({
            ...prevState,
            filteredDivisions,
            subclasses,
            selectedSubclass: null,
        }));
    }, [classes, divisions, selectedClass, selectedDepartments, selectedLine]);

    const handlePageClick = (event) => {
        handleChangeState('page', event.selected + 1);
    };

    const handleSelectRow = (index, record) => {
        const responseData = apiResponse?.data;

        let targetRow = responseData.find((row) => row.jan === record.jan);

        if (!targetRow) return;
        let existingIndex = selectedRows.findIndex((row) => row.jan === record.jan);

        if (selectedRows.findIndex((row) => row.jan === record.jan) !== -1) {
            selectedRows.splice(existingIndex, 1);
        } else {
            selectedRows.push(targetRow);
        }

        if (selectedRows.length === 0) {
            setState((prevState) => ({
                ...prevState,
                selectedRows: [],
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                selectedRows,
            }));
            // フォームの初期値を設定
            setFormData((formData) => ({
                ...formData,
                packingCategory: selectedRows[0].packingCategory,
                salesStartDate: formattedDate(new Date()),
            }));
        }
    };

    const handleChangeState = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleSubmitForm = () => {
        dispatch(setLoader(true));
        dispatch(clearErrors());
        setState((prevState) => ({
            ...prevState,
        }));
        const divisions = selectedDivision
            ? [selectedDivision]
            : selectedDepartments.map((department) => {
                  return {
                      departmentCode: department,
                      lineCode: 0,
                      classCode: 0,
                      subclassCode: 0,
                  };
              });

        const request = {
            divisions: divisions,
            itemName: itemName || null,
            jans: jan !== '' ? jan?.split(' ') : null,
            itemCategory: selectedItemCategory ? selectedItemCategory : null,
            stopPurchaseFlag: selectedStopPurchaseFlag ? parseInt(selectedStopPurchaseFlag) : null,
            changeRequestType: changeRequestType,
            page: page,
            limit: limit,
            sortField: table.heads.find((head) => head.field === sort.sortField)?.field,
            sortType: sort.sortType,
        };

        PriceSearchControllerService.search(request)
            .then((response) => {
                setState((prevState) => ({
                    ...prevState,
                    apiResponse: response,
                    isShowTableMaster: true,
                }));
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error, t('message.error.search'));
            })
            .finally(() => {
                dispatch(setLoader(false));

                if (env.FIREBASE_API_KEY) {
                    const analytics = getAnalytics();

                    request.jans.forEach((jan) => {
                        setUserProperties(analytics, {
                            jan: jan,
                        });
                    });

                    setUserProperties(analytics, {
                        division3_name:
                            selectedDivision.classCode === 0 && selectedDivision.subclassCode === 0 ? selectedDivision.divisionName : null,
                        division4_name:
                            selectedDivision.classCode !== 0 && selectedDivision.subclassCode === 0 ? selectedDivision.divisionName : null,
                        division5_name:
                            selectedDivision.classCode !== 0 && selectedDivision.subclassCode !== 0 ? selectedDivision.divisionName : null,
                    });

                    setUserProperties(analytics, {
                        item_name: request?.itemName,
                        item_category: request?.itemCategory,
                        stop_purchase_flag: request?.stopPurchaseFlag,
                        lowest_price_type: changeRequestType,
                        source_name: selectedSource,
                    });
                }
            });
    };

    const handleClearForm = () => {
        setState({
            ...DEFAULT_STATE,
            lines,
        });
    };

    const handleComplete = () => {
        history('/change-request/requesting');
    };

    const handleExportFile = async () => {
        if (selectedRows.length > 300) {
            dispatch(setToastsError('ダウンロード上限数を越えています。\n300件以下でダウンロードしてください。'));
            return;
        }

        // バックエンドからダウンロードするため、formを作成してリクエスト
        const form = document.createElement('form');
        form.action = `${env.BACKEND_BASE_URL}/api/export/excel`;
        form.method = 'POST';
        document.body.append(form);

        form.addEventListener('formdata', (e) => {
            const fd = e.formData;
            selectedRows.forEach((row, index) => {
                fd.set(`columns[${index}].jan`, row.jan);
                fd.set(`columns[${index}].itemName`, row.itemName);
                fd.set(`columns[${index}].capacityOfUnit`, row.capacityOfUnit);
                fd.set(`columns[${index}].lowestSellingPrice`, row.lowestSellingPrice);
                fd.set(`columns[${index}].edlpSellingPrice`, row.edlpSellingPrice);
                fd.set(`columns[${index}].lowestPriceType`, row.lowestPriceType);
                fd.set(`columns[${index}].packingCategory`, row.packingCategory);

                fd.set(`columns[${index}].competitorStoreCode`, selectedCompetitorStoreCode);
                fd.set(`columns[${index}].requesterName`, requesterName);
                fd.set(`columns[${index}].source`, selectedSource);
            });
        });

        form.submit();
        form.remove();
    };

    const handleRegistrationModalForm = async () => {
        // 入力値チェック
        if (validateFormInput(dispatch, formData, isFreshness)) return;

        const discountedRequestSellingPrice = parseInt(formData.discountedRequestSellingPrice);
        const requestData = selectedRows.map((row) => {
            return {
                ...row,
                ...formData,
                discountedRequestSellingPrice,
            };
        });

        // 下限値超過チェック
        if (limitOverValidation(dispatch, requestData)) {
            return;
        }

        // 禁止商品チェック
        if (prohibitedValidation(dispatch, requestData)) {
            return;
        }

        // 乖離を無視する場合があるため予めセットしておく
        setState((prevState) => ({
            ...prevState,
            requestData: requestData,
        }));

        // 乖離チェック
        if (await discountValidation(dispatch, requestData, isCompetitor)) {
            return;
        }

        handleChangeState('isShowModalRegister', true);
    };

    useEffect(() => {
        if (isContinue) {
            // モーダル表示後に処理を続行する場合
            handleChangeState('isShowModalRegister', true);
            dispatch(setIsContinue(false));
        }
    }, [dispatch, isContinue]);

    const handleCancelModalForm = () => {
        setFormData({
            ...DEFAULT_FORM_DATA,
            ...previousPageData,
        });
        setState((prevState) => ({
            ...prevState,
            selectedRows: [],
            isShowTableMaster: false,
        }));
    };

    const handleSubmitModalRegister = () => {
        handleChangeState('isShowModalRegister', false);
        dispatch(setLoader(true));
        dispatch(clearErrors());

        const request = isCompetitor
            ? ChangeRequestControllerService.createChangeRequestCompetitor('FORM', requestData)
            : isFreshness
              ? ChangeRequestControllerService.createChangeRequestFreshness('FORM', requestData)
              : ChangeRequestControllerService.createChangeRequestInstruction('FORM', requestData);

        request
            .then(() => {
                dispatch(setToastsSuccess('登録が完了しました。'));
                handleCancelModalForm();
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error, t('message.error.registration'));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    return (
        <div className="okc-inner">
            <Card
                isCompletion={!isHiddenButtonCompletion}
                handleSubmit={handleSubmitForm}
                handleClear={handleClearForm}
                handleCompletion={!isHiddenButtonCompletion && handleComplete}
            >
                <Label type="column" text={t('price_registration.search_form.product_name.title')} className="col-lg-2 mb-2 mb-lg-4" />
                <div className="col-lg-4 pr-lg-3 mb-4">
                    <Input
                        className="form-control form-control--input"
                        id="input-4"
                        type="text"
                        placeholder={t('price_registration.search_form.product_name.placeholder')}
                        autocomplete="off"
                        maxLength="60"
                        name="itemName"
                        onChange={(value) => handleChangeState('itemName', value)}
                        value={itemName}
                    />
                </div>
                <Label type="column" text={t('price_registration.search_form.line_name.title')} className="col-lg-2 pl-lg-3 mb-2 mb-lg-4" />
                <div className="col-lg-4 mb-4">
                    <SelectSearch
                        options={lines}
                        placeholder={t('price_registration.search_form.line_name.placeholder')}
                        onChange={(target) => {
                            handleChangeState('selectedDivision', target);
                            handleChangeState('selectedLine', target.value);
                        }}
                        value={selectedLine}
                    />
                </div>
                <Label type="column" text={t('price_registration.search_form.jan.title')} className="col-lg-2 mb-2 mb-lg-4" />
                <div className="col-lg-4 pr-lg-3 mb-4">
                    <Input
                        className="form-control form-control--input"
                        id="input-3"
                        type="text"
                        placeholder={t('price_registration.search_form.jan.placeholder')}
                        autocomplete="off"
                        name="jan"
                        onChange={(value) => handleChangeState('jan', value)}
                        value={jan}
                    />
                </div>
                <Label
                    type="column"
                    text={t('price_registration.search_form.class_name.title')}
                    className="col-lg-2 pl-lg-3 mb-2 mb-lg-4"
                />
                <div className="col-lg-4 mb-4">
                    <SelectSearch
                        options={classes}
                        placeholder={t('price_registration.search_form.class_name.placeholder')}
                        onChange={(target) => {
                            handleChangeState('selectedDivision', target);
                            handleChangeState('selectedClass', target.value);
                        }}
                        value={selectedClass}
                        disabled={!selectedLine}
                    />
                </div>
                <Label type="column" className="col-lg-2 mb-2 mb-lg-4" />
                <div className="col-lg-4 pr-lg-3 mb-4"></div>
                <Label
                    type="column"
                    text={t('price_registration.search_form.subclass_name.title')}
                    className="col-lg-2 pl-lg-3 mb-2 mb-lg-4"
                />
                <div className="col-lg-4 mb-4">
                    <SelectSearch
                        options={subclasses}
                        placeholder={t('price_registration.search_form.subclass_name.placeholder')}
                        onChange={(target) => {
                            handleChangeState('selectedDivision', target);
                            handleChangeState('selectedSubclass', target.value);
                        }}
                        value={selectedSubclass}
                        disabled={!selectedClass}
                    />
                </div>
                {!isFreshness && (
                    <>
                        <Label
                            type="column"
                            text={t('price_registration.search_form.product_category.title')}
                            className="col-lg-2 mb-2 mb-lg-4 form-check-label"
                        />
                        <div className="col-lg-4 pr-lg-3 mb-4">
                            <div className="row no-gutters w-100 js-okc-validate product-category">
                                <Choice
                                    id="categories"
                                    column="col-lg-4"
                                    type="radio"
                                    data={itemCategories}
                                    onChange={(target) => handleChangeState('selectedItemCategory', target[0])}
                                    checked={[selectedItemCategory]}
                                />
                            </div>
                        </div>
                    </>
                )}
                <Label
                    type="column"
                    text={t('price_registration.search_form.stop_purchase.title')}
                    className="col-lg-2 pl-lg-3 mb-2 mb-lg-4 form-check-label stop-purchase"
                />
                <div className="col-lg-4 mb-4">
                    <div className="row no-gutters w-100 js-okc-validate stop-purchase">
                        <Choice
                            id="stockStopped"
                            column="col-lg-4"
                            type="radio"
                            data={STOP_PURCHASE_FLAG}
                            onChange={(target) => handleChangeState('selectedStopPurchaseFlag', target[0])}
                            checked={[selectedStopPurchaseFlag]}
                        />
                    </div>
                </div>
            </Card>
            {isShowTableMaster && (
                <>
                    <Pagination pageCount={apiResponse?.totalPage} onPageChange={handlePageClick} />
                    <div className="okc-table okc-table__parent c-slide-top okc-table--margin-bottom20">
                        <SelectTable
                            heads={table.heads}
                            body={table.body}
                            dataTable={apiResponse?.data}
                            isSelectRow={true}
                            isMultiSelectRow={isSelectExcel}
                            onSelectRow={handleSelectRow}
                            selectedRows={selectedRows}
                        />
                    </div>
                </>
            )}
            {isShowTableMaster && selectedRows.length > 0 && (
                <>
                    <div
                        className={`okc-table okc-table__child ${isSelectExcel ? 'okc-table--margin-bottom18' : 'okc-table--margin-bottom'}`}
                    >
                        <ViewTable heads={tableSelect.heads} body={tableSelect.body} dataTable={selectedRows} />
                    </div>
                    {isSelectExcel ? (
                        // Excel
                        <div className="d-flex justify-content-end c-slide-top mb-5">
                            <Button
                                className="btn btn--primary btn-block"
                                id="buttonExport"
                                onClick={handleExportFile}
                                children={<span className="btn-text">{t('button.download')}</span>}
                            />
                        </div>
                    ) : (
                        // フォーム
                        <div id="result-form-wrapper">
                            <CardPriceSearch
                                formData={formData}
                                setFormData={setFormData}
                                taxRate={selectedRows[0]?.taxRate}
                                capacityOfUnit={selectedRows[0]?.capacityOfUnit}
                                quantityOfUnit={selectedRows[0]?.quantityOfUnit}
                                discountedLowestSellingPrice={selectedRows[0]?.discountedLowestSellingPrice}
                                isRegistration={true}
                            />
                            <div className="d-flex justify-content-end c-slide-top mb-5 submit-btn">
                                <Button className="btn btn--primary js-okc-validate-form" onClick={handleRegistrationModalForm}>
                                    <span className="btn-text">{t('button.registration')}</span>
                                </Button>
                                <Button className="btn btn--secondary ml-2" onClick={handleCancelModalForm}>
                                    <span className="btn-text">{t('button.cancel')}</span>
                                </Button>
                            </div>
                        </div>
                    )}
                </>
            )}
            <Modal
                size="xll"
                isShow={isShowModalRegister}
                onClose={() => handleChangeState('isShowModalRegister', false)}
                isShowIconClose="true"
                title={t('price_registration.registration_list_modal.title')}
                btnList={[
                    {
                        name: t('price_registration.registration_list_modal.ok_button'),
                        className: 'btn--primary',
                        onClick: () => handleSubmitModalRegister(),
                        disabled: false,
                    },
                    {
                        name: t('price_registration.registration_list_modal.cancel_button'),
                        className: 'btn--secondary master-edit-modal__submit',
                        onClick: () => handleChangeState('isShowModalRegister', false),
                        disabled: false,
                    },
                ]}
                children={<ViewTable heads={tableRequest.heads} body={tableRequest.body} dataTable={requestData} />}
            />
        </div>
    );
};

export default PriceSearch;
