import Input from '../../common/Input';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../common/Checkbox';
import Label from '../../common/Label';
import SelectSearch from '../../common/SelectSearch';
import Choice from '../../common/Choice';
import Card from '../../common/Card';
import { fetchDivisions } from '../../../redux/actions/api';
import { useDispatch, useSelector } from 'react-redux';
import ReactCalender from '../../common/Datepicker';

const ProhibitedForm = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { state, setState, handleSubmit, handleClear, onChangeType } = props;

    const constants = useSelector((state) => state.constants);
    const { divisions } = constants;

    const [stateDataDatePicker, setStateDataDatePicker] = useState({
        minStartDate: '',
        maxStartDate: '',
        minEndDate: '',
        maxEndDate: '',
    });
    const { minStartDate, maxStartDate, minEndDate, maxEndDate } = stateDataDatePicker;

    const {
        itemName,
        jan,
        departments,
        lines,
        classes,
        subclasses,
        selectedDepartment,
        selectedLine,
        selectedClass,
        selectedSubclass,
        lowerLimitFlag,
        deleteFlag,
        selectedLowestPriceType,
        prohibitedSearchType,
        startDate,
        endDate,
    } = state;

    useEffect(() => {
        dispatch(fetchDivisions());
    }, [dispatch]);

    const setDivisions = (data) => {
        const departments = data
            .filter((item) => item.departmentCode > 0 && item.lineCode === 0 && item.classCode === 0 && item.subclassCode === 0)
            .map((item) => {
                return {
                    ...item,
                    value: item.departmentCode,
                    label: item.divisionName,
                };
            });
        setState((prevState) => ({
            ...prevState,
            departments,
        }));
    };

    const handleFormClear = () => {
        handleClear();
        if (divisions) {
            setDivisions(divisions);
        }
    };

    // 初期表示時に部門をセット
    useEffect(() => {
        if (divisions) {
            setDivisions(divisions);
        }
    }, [divisions]);

    // 部門が変更された場合にラインをセット
    useEffect(() => {
        const filteredDivisions = divisions?.filter((item) => {
            return item.departmentCode === selectedDepartment && item.lineCode > 0 && item.classCode === 0 && item.subclassCode === 0;
        });

        const lines = filteredDivisions.map((item) => {
            return {
                ...item,
                value: item.lineCode,
                label: item.divisionName,
            };
        });

        setState((prevState) => ({
            ...prevState,
            filteredDivisions,
            lines,
            selectedLine: null,
            selectedClass: null,
            selectedSubclass: null,
        }));
    }, [divisions, selectedDepartment]);

    // ラインが変更された場合にクラスをセット
    useEffect(() => {
        const filteredDivisions = divisions?.filter((item) => {
            return (
                item.departmentCode === selectedDepartment &&
                item.lineCode === selectedLine &&
                item.classCode > 0 &&
                item.subclassCode === 0
            );
        });

        const classes = filteredDivisions.map((item) => {
            return {
                ...item,
                value: item.classCode,
                label: item.divisionName,
            };
        });

        setState((prevState) => ({
            ...prevState,
            filteredDivisions,
            classes,
            selectedClass: null,
            selectedSubclass: null,
        }));
    }, [divisions, lines, selectedDepartment, selectedLine]);

    // クラスが変更された場合にサブクラスをセット
    useEffect(() => {
        const filteredDivisions = divisions?.filter((item) => {
            return (
                item.departmentCode === selectedDepartment &&
                item.lineCode === selectedLine &&
                item.classCode === selectedClass &&
                item.subclassCode > 0
            );
        });

        const subclasses = filteredDivisions.map((item) => {
            return {
                ...item,
                value: item.subclassCode,
                label: item.divisionName,
            };
        });

        setState((prevState) => ({
            ...prevState,
            filteredDivisions,
            subclasses,
            selectedSubclass: null,
        }));
    }, [classes, divisions, selectedClass, selectedDepartment, selectedLine]);

    const handleChangeState = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleChangeType = (value) => {
        onChangeType(value);
    };

    return (
        <Card handleSubmit={handleSubmit} handleClear={handleFormClear}>
            <div className="row okc-card__row">
                <>
                    <Label
                        type="column"
                        className="col-lg-2 mb-2 mb-lg-4 form-check-label"
                        text={t('prohibited_product_list.search_form.prohibited_search_type.label')}
                    />
                    <div className="col-lg-4 mb-4" id="selling-price-type">
                        <div className="row no-gutters w-100 js-okc-validate">
                            <Choice
                                type="radio"
                                column="col-sm-4"
                                id="prohibitedSearchType"
                                onChange={(value) => {
                                    handleChangeState('prohibitedSearchType', value[0]);
                                    handleChangeType(value[0]);
                                }}
                                data={[
                                    {
                                        id: 'product',
                                        value: 'PRODUCT',
                                        label: t('prohibited_product_list.search_form.prohibited_search_type.product'),
                                    },
                                    {
                                        id: 'category',
                                        value: 'CATEGORY',
                                        label: t('prohibited_product_list.search_form.prohibited_search_type.category'),
                                    },
                                ]}
                                value={prohibitedSearchType}
                                checked={prohibitedSearchType}
                            />
                        </div>
                    </div>
                    <Label type="column" className="col-lg-2 pl-lg-3 mb-2 mb-lg-4" />
                    <div className="col-lg-4 pr-lg-3 mb-4"></div>
                </>
                {jan !== undefined && prohibitedSearchType == 'PRODUCT' && (
                    <>
                        <Label type="column" className="col-lg-2 mb-2 mb-lg-4" text={t('prohibited_product_list.search_form.jan.title')} />
                        <div className="col-lg-4 mb-4">
                            <Input
                                className="form-control form-control--input"
                                value={jan}
                                placeholder={t('prohibited_product_list.search_form.jan.placeholder')}
                                id="jan"
                                type="text"
                                autocomplete="off"
                                onChange={(value) => handleChangeState('jan', value)}
                            />
                        </div>
                    </>
                )}
                {itemName !== undefined && prohibitedSearchType == 'PRODUCT' && (
                    <>
                        <Label
                            type="column"
                            className="col-lg-2 pl-lg-3 mb-2 mb-lg-4"
                            text={t('prohibited_product_list.search_form.product_name.title')}
                        />
                        <div className="col-lg-4 pr-lg-3 mb-4">
                            <Input
                                className="form-control form-control--input"
                                value={itemName}
                                placeholder={t('prohibited_product_list.search_form.product_name.placeholder')}
                                id="item-name"
                                type="text"
                                autocomplete="off"
                                onChange={(value) => handleChangeState('itemName', value)}
                            />
                        </div>
                    </>
                )}
                <Label type="column" className="col-lg-2 mb-2 mb-lg-4" text={t('prohibited_product_list.search_form.division.title')} />
                <div className="col-lg-4 pr-lg-3 mb-4">
                    <SelectSearch
                        options={departments}
                        placeholder={t('prohibited_product_list.search_form.division.placeholder')}
                        onChange={(target) => {
                            handleChangeState('selectedDivision', target);
                            handleChangeState('selectedDepartment', target.value);
                        }}
                        value={selectedDepartment}
                    />
                </div>
                <Label
                    type="column"
                    className="col-lg-2 pl-lg-3 mb-2 mb-lg-4"
                    text={t('prohibited_product_list.search_form.line_name.title')}
                />
                <div className="col-lg-4 mb-4" data-select2-id="select2-data-49-gnvu">
                    <SelectSearch
                        options={lines}
                        placeholder={t('price_registration.search_form.line_name.placeholder')}
                        onChange={(target) => {
                            handleChangeState('selectedDivision', target);
                            handleChangeState('selectedLine', target.value);
                        }}
                        value={selectedLine}
                        disabled={!selectedDepartment}
                    />
                </div>
                <Label type="column" className="col-lg-2 mb-2 mb-lg-4" text={t('prohibited_product_list.search_form.class_name.title')} />
                <div className="col-lg-4 pr-lg-3 mb-4">
                    <SelectSearch
                        options={classes}
                        placeholder={t('price_registration.search_form.class_name.placeholder')}
                        onChange={(target) => {
                            handleChangeState('selectedDivision', target);
                            handleChangeState('selectedClass', target.value);
                        }}
                        value={selectedClass}
                        disabled={!selectedLine}
                    />
                </div>
                <Label
                    type="column"
                    className="col-lg-2 pl-lg-3 mb-2 mb-lg-4"
                    text={t('prohibited_product_list.search_form.subclass_name.title')}
                />
                <div className="col-lg-4 mb-4">
                    <SelectSearch
                        options={subclasses}
                        placeholder={t('price_registration.search_form.subclass_name.placeholder')}
                        onChange={(target) => {
                            handleChangeState('selectedDivision', target);
                            handleChangeState('selectedSubclass', target.value);
                        }}
                        value={selectedSubclass}
                        disabled={!selectedClass}
                    />
                </div>
                <Label type="column" className="col-lg-2 mb-2 mb-lg-4" text={t('prohibited_product_list.search_form.start_date.title')} />
                <div className="col-lg-4 pr-lg-3 mb-4">
                    <ReactCalender
                        onChange={(value) => handleChangeState('startDate', value)}
                        value={startDate}
                        placeholder={t('prohibited_product_list.search_form.start_date.placeholder')}
                        minDate={minStartDate}
                        maxDate={maxStartDate}
                    />
                </div>
                <Label
                    type="column"
                    className="col-lg-2 pl-lg-3 mb-2 mb-lg-4"
                    text={t('prohibited_product_list.search_form.end_date.title')}
                />
                <div className="col-lg-4 mb-4">
                    <ReactCalender
                        onChange={(value) => handleChangeState('endDate', value)}
                        value={endDate}
                        placeholder={t('prohibited_product_list.search_form.end_date.placeholder')}
                        minDate={startDate ? new Date(startDate) : minEndDate}
                        maxDate={maxEndDate}
                    />
                </div>
                {lowerLimitFlag !== undefined && prohibitedSearchType == 'PRODUCT' && (
                    <>
                        <Label
                            type="column"
                            className="col-lg-2 mb-2 mb-lg-4 mt-lg-2 align-self-start"
                            text={t('prohibited_product_list.search_form.lower_limit.title')}
                        />
                        <div className="col-lg-4 mb-5 prohibited-products__checkboxes">
                            <div className="row m-0 js-okc-validate">
                                <Checkbox
                                    id="lower-limit"
                                    label={' '}
                                    onChange={() => handleChangeState('lowerLimitFlag', !lowerLimitFlag)}
                                    value={lowerLimitFlag}
                                    checked={lowerLimitFlag}
                                />
                            </div>
                        </div>
                    </>
                )}
                {deleteFlag !== undefined && prohibitedSearchType == 'PRODUCT' && (
                    <>
                        <Label
                            type="column"
                            className={`col-lg-2 mb-2 mb-lg-4 mt-lg-2 align-self-start ${lowerLimitFlag !== undefined ? 'pl-lg-3' : ''}`}
                            text={t('prohibited_product_list.search_form.status.title')}
                        />
                        <div className="col-lg-4 mb-4 prohibited-products__checkboxes">
                            <div className="row m-0 js-okc-validate">
                                <Checkbox
                                    column="col-xl-3 col-lg-4 col-md-5"
                                    id="delete-flag"
                                    label={t('prohibited_product_list.search_form.status.label')}
                                    onChange={() => handleChangeState('deleteFlag', !deleteFlag)}
                                    value={deleteFlag}
                                    checked={deleteFlag}
                                />
                            </div>
                        </div>
                    </>
                )}
                <Label
                    type="column"
                    className={`col-lg-2 mb-2 mb-lg-4 form-check-label stop-purchase ${!(lowerLimitFlag !== undefined) && deleteFlag !== undefined ? 'pl-lg-3' : ''}`}
                    text={t('prohibited_product_list.search_form.selling_price_type.title')}
                />
                <div className="col-lg-4 mb-4" id="selling-price-type">
                    <div className="row no-gutters w-100 js-okc-validate">
                        <Choice
                            type="radio"
                            column="col-sm-4"
                            onChange={(value) => handleChangeState('selectedLowestPriceType', value[0])}
                            data={[
                                {
                                    id: 'selling-price-type-competitive',
                                    value: 'COMPETITOR',
                                    label: t('prohibited_product_list.search_form.selling_price_type.competitive'),
                                },
                                {
                                    id: 'selling-price-type-freshness',
                                    value: 'FRESHNESS',
                                    label: t('prohibited_product_list.search_form.selling_price_type.freshness'),
                                },
                            ]}
                            value={selectedLowestPriceType}
                            checked={selectedLowestPriceType}
                        />
                    </div>
                </div>
            </div>
        </Card>
    );
};
export default ProhibitedForm;
