export const createStoreOptions = (stores) => {
    return (
        stores?.map((item) => ({
            value: item.storeCode,
            label: `${item.storeCode} ${item.storeName}`,
        })) ?? []
    );
};

export const createCompetitorChainOptions = (competitorChains) => {
    return (
        competitorChains?.map((item) => ({
            value: item.competitorChainCode,
            label: item.competitorChainName,
        })) ?? []
    );
};

export const createCompetitorStoreOptions = (competitorStores, selectedCompetitorChainCode) => {
    return (
        competitorStores
            ?.filter((item) => item.competitorStoreCode.startsWith(selectedCompetitorChainCode))
            .map((item) => ({
                value: item.competitorStoreCode,
                label: item.competitorStoreName,
            })) ?? []
    );
};

export const createCompetitorStoreMultiOptions = (competitorStores, selectedCompetitorChainCodes) => {
    return (
        competitorStores
            ?.filter((item) => selectedCompetitorChainCodes.some((code) => item.competitorStoreCode.startsWith(code)))
            .map((item) => ({
                value: item.competitorStoreCode,
                label: item.competitorStoreName,
            })) ?? []
    );
};

export const createDepartmentOptions = (departments) => {
    return departments.map((department) => ({
        value: department.value,
        label: department.label,
    }));
};
