import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import '../../assets/css/common/select.css';

import Input from './Input';

const SelectSearch = (props) => {
    const { onChange, className, options, value, disabled, placeholder, isSearch = true, isCheckBorder = false } = props;

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleOpen = () => {
        setIsOpen((prev) => !prev);
    };

    const onSelectChange = (value) => {
        toggleOpen();
        onChange(value);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const getSelectedLabel = (value) => {
        if (Array.isArray(value)) {
            return value.map((v) => options.find((option) => option.value === v)?.label).filter(Boolean);
        } else if (value) {
            return options.find((option) => option.value === value)?.label;
        }
        return null;
    };

    return (
        <div
            ref={dropdownRef}
            style={{
                width: isOpen ? '100%' : '100%',
                cursor: 'pointer',
                borderLeft: isOpen ? '1px solid #d66f00' : '0px solid #d66f00',
                borderRight: isOpen ? '1px solid #d66f00' : '0px solid #d66f00',
                borderBottom: isOpen ? '1px solid #d66f00' : '',
                borderBottomRightRadius: isOpen ? '2px' : '',
                borderBottomLeftRadius: isOpen ? '2px' : '',
                borderTopRightRadius: isOpen ? '4px' : '',
                borderTopLeftRadius: isOpen ? '4px' : '',
                position: isOpen ? 'absolute' : '',
                top: '0px',
                zIndex: isOpen ? '2000' : '0',
                background: 'white',
            }}
        >
            <Dropdown
                isOpen={isOpen}
                onClose={toggleOpen}
                target={
                    <div className="input_dropdown" onClick={toggleOpen}>
                        <Input
                            type="select"
                            value={value ? getSelectedLabel(value) : ''}
                            style={
                                !isCheckBorder
                                    ? {
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '8px 16px',
                                          borderBottomRightRadius: isOpen ? '0px' : '',
                                          borderBottomLeftRadius: isOpen ? '0px' : '',
                                          border: isOpen ? '1px solid #d66f00' : '1px solid #e1e6ea',
                                          borderLeft: isOpen ? '0px solid #e1e6ea' : '1px solid #e1e6ea',
                                          borderRight: isOpen ? '0px solid #e1e6ea' : '1px solid #e1e6ea',
                                          cursor: 'pointer',
                                      }
                                    : {
                                          borderColor: '#eb2013',
                                      }
                            }
                            disabled={disabled}
                            placeholder={placeholder}
                        />
                        {!disabled && (
                            <div className="icon_arrow">
                                <Chevron isOpen={isOpen} />
                            </div>
                        )}
                    </div>
                }
            >
                <Select
                    className={`select-wrapper ${className}`}
                    classNamePrefix="select"
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen
                    onChange={(value) => onSelectChange(value)}
                    options={options}
                    placeholder=""
                    styles={selectStyles(isSearch)}
                    tabSelectsValue={false}
                    value={value}
                    menuShouldScrollIntoView={false}
                    closeMenuOnSelect={false}
                />
            </Dropdown>
        </div>
    );
};

const Menu = (props) => {
    return <div className="menu" style={{}} {...props} />;
};

const Blanket = (props) => <div className="blanket" {...props} />;

const Dropdown = ({ children, isOpen, target, onClose, style }) => (
    <div style={{ ...style }}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
        {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
);

const Svg = (p) => <svg width="18" height="16" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />;

const Chevron = ({ isOpen }) => (
    <Svg style={{ marginLeft: 'auto' }}>
        <path d={isOpen ? 'M12 8l6 6H6z' : 'M12 16l-6-6h12z'} fill={isOpen ? '#d66f00' : 'currentColor'} />
    </Svg>
);

const DropdownIndicator = () => (
    <div>
        <span className="dropdown-indicator-triangle"></span>
    </div>
);

const selectStyles = (isSearch) => ({
    control: (base) => ({
        ...base,
        fontSize: '12px',
        margin: '6px',
        border: '1px solid #ef7a00 !important',
        borderRadius: '0px',
        boxShadow: 'none',
        '&:focus': {
            border: '1px solid #ef7a00 !important',
        },
        display: isSearch ? 'block' : 'none',
    }),
    menu: () => ({
        boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)',
    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: 150, // set the maximum height
        overflowY: 'auto', // enable vertical scrolling
    }),
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? '#ef7a00' : '',
        borderColor: '#ef7a00',
    }),
});

export default SelectSearch;
