import {
    setExcelValidation,
    setFiftyDiscount,
    setLimitOver,
    setProhibited,
    setTenDiscount,
    setThirtyDiscount,
} from '../../redux/actions/modal';
import { getSellingPrice, isDeviated } from './formula';
import { setToastsError } from '../../redux/actions/common';

export const validateFormInput = (dispatch, formData, isFreshness) => {
    if (!formData.expandSalesFloor && !isFreshness) {
        dispatch(setToastsError('売場展開を選択してください。'));
        return true;
    }
    if (!formData.discountedRequestSellingPrice) {
        dispatch(setToastsError('売価を入力してください。'));
        return true;
    }
    if (formData.discountedRequestSellingPrice < 1) {
        dispatch(setToastsError('売価は1以上の数値を入力してください。'));
        return true;
    }
    if (!formData.packingCategory && !isFreshness) {
        dispatch(setToastsError('販売方法・荷姿を選択してください。'));
        return true;
    }
    if (!formData.saleType && !isFreshness) {
        dispatch(setToastsError('特売種別・期間を選択してください。'));
        return true;
    }
    if (!formData.salesStartDate) {
        dispatch(setToastsError('開始日を選択してください。'));
        return true;
    }
    if (!formData.salesEndDate) {
        dispatch(setToastsError('終了日を選択してください。'));
        return true;
    }
    if (!/^\d{4}\/\d{1,2}\/\d{1,2}$/.test(formData.salesStartDate)) {
        dispatch(setToastsError('開始日は「yyyy/mm/dd」の形式で入力してください。'));
        return true;
    }
    if (!/^\d{4}\/\d{1,2}\/\d{1,2}$/.test(formData.salesEndDate)) {
        dispatch(setToastsError('終了日は「yyyy/mm/dd」の形式で入力してください。'));
        return true;
    }
    if (formData.salesStartDate > formData.salesEndDate) {
        dispatch(setToastsError('終了日は開始日以降を選択してください。'));
        return true;
    }
    if (getDiffDays(formData) > 31) {
        dispatch(setToastsError('終了日は「開始日から31日以内」の日付を入力してください。'));
        return true;
    }
    if (isFreshness && !formData.freshnessReason) {
        dispatch(setToastsError('鮮度起因値下げ理由を入力してください。'));
        return true;
    }
    return false;
};

export const validateExcelInput = (dispatch, formData) => {
    const errors = [];
    formData.forEach((row) => {
        if (!row.expandSalesFloor) {
            errors.push({
                rowNumber: row.id,
                message: '売場展開を選択してください。',
            });
        }
        if (!row.discountedRequestSellingPrice) {
            errors.push({
                rowNumber: row.id,
                message: '売価を入力してください。',
            });
        }
        if (row.discountedRequestSellingPrice < 1) {
            errors.push({
                rowNumber: row.id,
                message: '売価は1以上の数値を入力してください。',
            });
        }
        if (!row.packingCategory) {
            errors.push({
                rowNumber: row.id,
                message: '販売方法・荷姿を選択してください。',
            });
        }
        if (!row.saleType) {
            errors.push({
                rowNumber: row.id,
                message: '特売種別・期間を選択してください。',
            });
        }
        if (!row.salesStartDate) {
            errors.push({
                rowNumber: row.id,
                message: '開始日を選択してください。',
            });
        }
        if (!row.salesEndDate) {
            errors.push({
                rowNumber: row.id,
                message: '終了日を選択してください。',
            });
        }
        if (!/^\d{4}\/\d{1,2}\/\d{1,2}$/.test(row.salesStartDate)) {
            errors.push({
                rowNumber: row.id,
                message: '開始日は「yyyy/mm/dd」の形式で入力してください。',
            });
        }
        if (!/^\d{4}\/\d{1,2}\/\d{1,2}$/.test(row.salesEndDate)) {
            errors.push({
                rowNumber: row.id,
                message: '終了日は「yyyy/mm/dd」の形式で入力してください。',
            });
        }
        if (row.salesStartDate > row.salesEndDate) {
            errors.push({
                rowNumber: row.id,
                message: '終了日は開始日以降を選択してください。',
            });
        }
        if (getDiffDays(row) > 31) {
            errors.push({
                rowNumber: row.id,
                message: '終了日は「開始日から31日以内」の日付を入力してください。',
            });
        }
    });

    if (errors.length > 0) {
        setExcelValidation({
            isShow: true,
            errors,
        });
        return true;
    }

    return false;
};

export const getDiffDays = (formData) => {
    const startDate = new Date(formData.salesStartDate);
    const endDate = new Date(formData.salesEndDate);
    const diff = endDate.getTime() - startDate.getTime();
    return diff / (1000 * 60 * 60 * 24);
};

export const limitOverValidation = (dispatch, items) => {
    const limitOverItems = items.filter(
        (item) => item.prohibited && item.lowerLimit && item.lowerLimit > item.discountedRequestSellingPrice,
    );
    if (limitOverItems.length > 0) {
        dispatch(
            setLimitOver({
                isShow: true,
                items: limitOverItems,
            }),
        );
        return true;
    }
    return false;
};

export const prohibitedValidation = (dispatch, items) => {
    const prohibitedItems = items.filter((item) => item.prohibited);
    if (prohibitedItems.length > 0) {
        dispatch(
            setProhibited({
                isShow: true,
                items: prohibitedItems,
            }),
        );
        return true;
    }
    return false;
};

export const discountValidation = async (dispatch, items, isCompetitor) => {
    for (const item of items) {
        if (item.discountedRequestSellingPrice === 0) {
            dispatch(setFiftyDiscount(true));
            return true;
        }

        const requestSellingPrice = await getSellingPrice(item.discountedRequestSellingPrice, item.discountedCategory);
        const saratokuSellingPrice = item.saratokuSellingPrice;
        const edlpSellingPrice = item.edlpSellingPrice;
        const comparePrice = saratokuSellingPrice && saratokuSellingPrice < edlpSellingPrice ? saratokuSellingPrice : edlpSellingPrice;

        if (isCompetitor && (await isDeviated(requestSellingPrice, comparePrice, 50))) {
            dispatch(setFiftyDiscount(true));
            return true;
        } else if (await isDeviated(requestSellingPrice, comparePrice, 30)) {
            dispatch(setThirtyDiscount(true));
            return true;
        } else if (isCompetitor && (await isDeviated(requestSellingPrice, comparePrice, 10))) {
            dispatch(setTenDiscount(true));
            return true;
        }
    }
    return false;
};
