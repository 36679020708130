import Modal from '../../common/Modal';
import Card from '../../common/Card';
import SelectSearch from '../../common/SelectSearch';
import { createCompetitorChainOptions, createCompetitorStoreOptions, createStoreOptions } from '../../../utils/helpers/option';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBusinessFormats, setLoader } from '../../../redux/actions/api';
import { CompetitorMasterControllerService } from '../../../services';
import { setToastsError, setToastsSuccess } from '../../../redux/actions/common';
import apiErrorHandler from '../../../api/apiErrorHandler';
import { useLocation } from 'react-router-dom';
import { clearErrors } from '../../../redux/actions/error';

const DEFAULT_STATE = {
    selectedDistance: '',
    selectedRank: '',
    notificationFlag: '',

    apiResponse: null,
    selectedModalCompetitorChainCode: '',
    selectedModalCompetitorStoreCode: '',
    selectedModalStoreCode: '',

    deleteIndex: null,
    isShowConfirmDeleteModal: false,
};

const CompetitorCombinationMasterModal = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const { isShow, handleCloseModal } = props;

    const constants = useSelector((state) => state.constants);
    const { stores, competitorStores, competitorChains, distances, frequencyRanks } = constants;

    const [state, setState] = useState(DEFAULT_STATE);

    const {
        selectedDistance,
        selectedRank,
        notificationFlag,

        apiResponse,
        selectedModalCompetitorChainCode,
        selectedModalCompetitorStoreCode,
        selectedModalStoreCode,

        deleteIndex,
        isShowConfirmDeleteModal,
    } = state;

    useEffect(() => {
        dispatch(clearErrors());
        dispatch(fetchBusinessFormats());
    }, [dispatch, location]);

    const getCompetitorMaster = () => {
        dispatch(setLoader(true));
        dispatch(clearErrors());
        CompetitorMasterControllerService.searchCompetitorCombinations(
            null,
            null,
            selectedModalCompetitorChainCode,
            selectedModalCompetitorStoreCode,
        )
            .then((response) => {
                handleChangeState('apiResponse', response);
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        if (selectedModalCompetitorStoreCode) {
            getCompetitorMaster(null, null, selectedModalCompetitorChainCode, selectedModalCompetitorStoreCode, true);
        }
    }, [selectedModalCompetitorStoreCode]);

    const handleChangeState = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleChangeApiResponse = (index, field, value) => {
        const newApiResponseData = apiResponse.data;
        newApiResponseData[index][field] = value;
        handleChangeState('apiResponse', {
            ...apiResponse,
            data: newApiResponseData,
        });
    };

    const handleSubmit = () => {
        dispatch(setLoader(true));
        dispatch(clearErrors());
        CompetitorMasterControllerService.createCompetitorMaster({
            storeCode: selectedModalStoreCode ? selectedModalStoreCode : null,
            competitorStoreCode: selectedModalCompetitorStoreCode ? selectedModalCompetitorStoreCode : null,
            distance: selectedDistance ? selectedDistance : null,
            rank: selectedRank ? selectedRank : null,
            notificationFlag: notificationFlag ? notificationFlag : null,
            combinationInfos: apiResponse.data,
        })
            .then(() => {
                dispatch(setToastsSuccess('競合店マスタを更新しました。'));
                setState(DEFAULT_STATE);
                handleCloseModal();
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error, t('message.error.update'));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const toggleDeleteCheckbox = (index) => {
        handleChangeState('deleteIndex', index);
        handleChangeState('isShowConfirmDeleteModal', true);
    };

    const handleDelete = () => {
        const deleteFlag = apiResponse.data[deleteIndex].deleteFlag;
        handleChangeApiResponse(deleteIndex, 'deleteFlag', !deleteFlag);
        handleChangeState('isShowConfirmDeleteModal', false);
    };

    const handleResetAndCloseModal = () => {
        setState(DEFAULT_STATE);
        handleCloseModal();
    };

    return (
        <>
            <Modal
                size="xl"
                isShow={isShow}
                onClose={handleResetAndCloseModal}
                isShowIconClose="true"
                title={t('update_competitor_master.registration-modal.header')}
                btnList={[
                    {
                        name: t('update_competitor_master.registration-modal.footer.primary-btn'),
                        className: 'btn--primary',
                        onClick: () => handleSubmit(),
                    },
                    {
                        name: t('update_competitor_master.registration-modal.footer.secondary-btn'),
                        className: 'btn--secondary',
                        onClick: () => handleResetAndCloseModal(),
                    },
                ]}
            >
                <div className="okc-card okc-result-form">
                    <Card type="modal_card">
                        <div className="row okc-card__row">
                            <div className="col-lg-3 okc-result-form__title">
                                <p>{t('update_competitor_master.registration-modal.body.chain.label')}</p>
                            </div>
                            <div className="col-lg-8 offset-lg-1 d-flex">
                                <div className="row w-100">
                                    <div className="col-12 mb-2 js-switch-target" data-select2-id="select2-data-1149-isnm">
                                        <SelectSearch
                                            onChange={(option) => handleChangeState('selectedModalCompetitorChainCode', option.value)}
                                            options={createCompetitorChainOptions(competitorChains)}
                                            value={selectedModalCompetitorChainCode}
                                            placeholder={t('price_request.competitor_name.placeholder')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row okc-card__row">
                            <div className="col-lg-3 okc-result-form__title">
                                <p>{t('update_competitor_master.registration-modal.body.competitor.store.label')}</p>
                            </div>
                            <div className="col-lg-8 offset-lg-1 d-flex">
                                <div className="row w-100">
                                    <div className="col-12 mb-2 js-switch-target" data-select2-id="select2-data-1421-wogk">
                                        <SelectSearch
                                            onChange={(option) => handleChangeState('selectedModalCompetitorStoreCode', option.value)}
                                            options={createCompetitorStoreOptions(competitorStores, selectedModalCompetitorChainCode)}
                                            value={selectedModalCompetitorStoreCode}
                                            disabled={!selectedModalCompetitorChainCode}
                                            placeholder={t('price_request.competitor_store_name.placeholder')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {selectedModalCompetitorStoreCode && (
                            <div className="competitor-store-list-wrapper">
                                <div className="competitor-store-header">
                                    <div className="row okc-card__row">
                                        <div className="col-lg-10 store-info">
                                            <div className="col-lg-3">
                                                {t('update_competitor_master.registration-modal.body.store-list.store')}
                                            </div>
                                            <div className="col-lg-3">
                                                {t('update_competitor_master.registration-modal.body.store-list.distance')}
                                            </div>
                                            <div className="col-lg-3">
                                                {t('update_competitor_master.registration-modal.body.store-list.rank')}
                                            </div>
                                        </div>
                                        <div className="col-lg-1 flex-column">
                                            {t('update_competitor_master.registration-modal.body.store-list.notification_flag')}
                                        </div>
                                        <div className="col-lg-1 flex-column">
                                            {t('update_competitor_master.registration-modal.body.store-list.delete_flag')}
                                        </div>
                                    </div>
                                </div>
                                <div className="competitor-store-list">
                                    {apiResponse?.data.map((item, index) => (
                                        <div className="row okc-card__row">
                                            <div key={item.id} className="col-lg-10 store-info">
                                                <div className="col-lg-3">{item.storeCode + ' ' + item.storeName}</div>
                                                <div className="col-lg-3">
                                                    <SelectSearch
                                                        onChange={(option) => {
                                                            handleChangeApiResponse(index, 'distance', option.value);
                                                        }}
                                                        options={distances}
                                                        value={item.distance}
                                                        placeholder={t(
                                                            'update_competitor_master.registration-modal.body.distance.placeholder',
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-lg-3">
                                                    <SelectSearch
                                                        onChange={(option) => {
                                                            handleChangeApiResponse(index, 'storeRank', option.value);
                                                        }}
                                                        options={frequencyRanks}
                                                        value={item.storeRank}
                                                        placeholder={t('update_competitor_master.registration-modal.body.rank.placeholder')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-1 flex-column">
                                                <div key={index} className={`form-check custom-checkbox`}>
                                                    <input
                                                        className="form-check-input"
                                                        onChange={() =>
                                                            handleChangeApiResponse(index, 'notificationFlag', !item.notificationFlag)
                                                        }
                                                        type="checkbox"
                                                        id={`notificationFlag-${item.id}`}
                                                        checked={item.notificationFlag}
                                                    />
                                                    <label className="form-check-label" htmlFor={`notificationFlag-${item.id}`}></label>
                                                </div>
                                            </div>
                                            <div className="col-lg-1 flex-column">
                                                <div key={index} className={`form-check custom-checkbox`}>
                                                    <input
                                                        className="form-check-input"
                                                        onChange={() => toggleDeleteCheckbox(index)}
                                                        type="checkbox"
                                                        id={`deleteFlag-${item.id}`}
                                                        checked={!item.deleteFlag}
                                                    />
                                                    <label className="form-check-label" htmlFor={`deleteFlag-${item.id}`}></label>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className="row okc-card__row justify-content-space-evenly px-5">
                            <div className="col-lg-3 flex-column">
                                <SelectSearch
                                    onChange={(option) => handleChangeState('selectedModalStoreCode', option.value)}
                                    options={createStoreOptions(stores)}
                                    value={selectedModalStoreCode}
                                    placeholder={t('price_request.oke_store_name.placeholder')}
                                />
                            </div>
                            <div className="col-lg-3 flex-column" style={{ marginLeft: '30px' }}>
                                <SelectSearch
                                    onChange={(option) => {
                                        handleChangeState('selectedDistance', option.value);
                                    }}
                                    options={distances}
                                    value={selectedDistance}
                                    placeholder={t('update_competitor_master.registration-modal.body.distance.placeholder')}
                                />
                            </div>
                            <div className="col-lg-3 flex-column" style={{ marginLeft: '30px' }}>
                                <SelectSearch
                                    onChange={(option) => {
                                        handleChangeState('selectedRank', option.value);
                                    }}
                                    options={frequencyRanks}
                                    value={selectedRank}
                                    placeholder={t('update_competitor_master.registration-modal.body.rank.placeholder')}
                                />
                            </div>
                            <div className="col-lg-1 flex-column">
                                <div className={`form-check custom-checkbox`} style={{ marginLeft: '85px', width: '38px', height: '38px' }}>
                                    <input
                                        className="form-check-input"
                                        onChange={() => handleChangeState('notificationFlag', !notificationFlag)}
                                        type="checkbox"
                                        id={`notificationFlag`}
                                        value={notificationFlag}
                                        checked={notificationFlag}
                                    />
                                    <label
                                        className="form-check-label competitor-combination-master-modal"
                                        htmlFor={`notificationFlag`}
                                        style={{ width: '38px', height: '38px', padding: 0 }}
                                    ></label>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </Modal>

            <Modal
                size="sm"
                isShow={isShowConfirmDeleteModal}
                onClose={() => handleChangeState('isShowConfirmDeleteModal', false)}
                isShowIconClose="true"
                title={t('update_competitor_master.delete-modal.header')}
                btnList={[
                    {
                        name: t('update_competitor_master.delete-modal.primary_btn'),
                        className: 'btn--primary',
                        onClick: () => handleDelete(),
                    },
                    {
                        name: t('update_competitor_master.delete-modal.secondary_btn'),
                        className: 'btn--secondary',
                        onClick: () => handleChangeState('isShowConfirmDeleteModal', false),
                    },
                ]}
            >
                <p className="text-center">削除、または復元しますか？</p>
            </Modal>
        </>
    );
};

export default CompetitorCombinationMasterModal;
