import Card from './../../components/common/Card';
import Label from '../../components/common/Label';
import { useTranslation } from 'react-i18next';
import SelectSearch from '../../components/common/SelectSearch';
import ReactCalender from '../../components/common/Datepicker';
import '../../assets/css/pages/stores/export-summary.css';
import { useEffect, useState } from 'react';
import { maxEndDateMin, maxStartDateMin, minRequestedDateMin, minStartDateMin } from '../../utils/helpers/date';
import env from '../../configs/env';
import {
    createCompetitorChainOptions,
    createCompetitorStoreMultiOptions,
    createCompetitorStoreOptions,
    createDepartmentOptions,
} from '../../utils/helpers/option';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompetitorChainsByStoreCode, fetchCompetitorStoresByStoreCode, fetchDepartments } from '../../redux/actions/api';
import MultiSelect from '../../components/common/MultiSelect';

const ExportSummary = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const constants = useSelector((state) => state.constants);
    const { departments, competitorChains, competitorStores } = constants;

    const [state, setState] = useState({
        departmentCode: '',
        competitorChainCode: [],
        competitorStoreCode: [],
        competitorChainCodes: [],
        competitorStoreCodes: [],
        requestedDateMin: '',
        requestedDateMax: '',
        startDateMin: '',
        startDateMax: '',
        endDateMin: '',
        endDateMax: '',
        isCheckError: false,
        isCheckRequestedDateMin: false,
        isCheckRequestedDateMax: false,
    });

    const {
        departmentCode,
        competitorChainCode,
        competitorStoreCode,
        competitorChainCodes,
        competitorStoreCodes,
        requestedDateMin,
        requestedDateMax,
        startDateMin,
        startDateMax,
        endDateMin,
        endDateMax,
        isCheckError,
        isCheckRequestedDateMin,
        isCheckRequestedDateMax,
    } = state;

    useEffect(() => {
        dispatch(fetchDepartments());
        dispatch(fetchCompetitorChainsByStoreCode());
        dispatch(fetchCompetitorStoresByStoreCode());
    }, []);

    const handleChangeState = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleChangeMultiState = (field, option = []) => {
        setState((prevState) => ({
            ...prevState,
            [field]: option,
        }));
    };

    const handleChainChange = (option, values) => {
        const newCompetitorStoreCode = competitorStoreCode?.filter((store) => values.some((value) => store.value.startsWith(value)));

        const newCompetitorStoreCodes = competitorStoreCodes?.filter((store) => values.some((value) => store.startsWith(value)));

        handleChangeMultiState('competitorStoreCode', newCompetitorStoreCode);
        handleChangeMultiState('competitorStoreCodes', newCompetitorStoreCodes);
    };

    const filteredChildOptions = competitorChainCode ? createCompetitorStoreMultiOptions(competitorStores, competitorChainCodes) : [];

    const handleClearForm = () => {
        setState((prevState) => ({
            ...prevState,
            requestedDateMin: '',
            requestedDateMax: '',
            departmentCode: '',
            competitorChainCode: [],
            competitorStoreCode: [],
            competitorChainCodes: [],
            competitorStoreCodes: [],
            startDateMin: '',
            startDateMax: '',
            endDateMin: '',
            endDateMax: '',
            isCheckError: false,
            isCheckRequestedDateMin: false,
            isCheckRequestedDateMax: false,
        }));
    };

    const handleSubmitForm = () => {
        if (!requestedDateMin && !requestedDateMax) {
            setState((prevState) => ({
                ...prevState,
                isCheckError: true,
                isCheckRequestedDateMin: false,
                isCheckRequestedDateMax: false,
            }));
        } else if (!requestedDateMin || !requestedDateMax) {
            setState((prevState) => ({
                ...prevState,
                isCheckRequestedDateMin: !requestedDateMin,
                isCheckRequestedDateMax: !requestedDateMax,
                isCheckError: false,
            }));
        } else {
            handleExportFile();
            handleClearForm();
        }
    };

    const handleExportFile = () => {
        // バックエンドからダウンロードするため、formを作成してリクエスト
        const form = document.createElement('form');
        form.action = `${env.BACKEND_BASE_URL}/api/export-summary`;
        form.method = 'POST';
        document.body.append(form);

        form.addEventListener('formdata', (e) => {
            const fd = e.formData;
            for (const key in state) {
                if (state[key] !== '') {
                    fd.append(key, state[key]);
                }
            }
        });

        form.submit();
        form.remove();
    };

    return (
        <div className="export-summary">
            <div className="okc-inner">
                <Card handleSubmit={handleSubmitForm} handleClear={handleClearForm} isExport={true} toggle={false}>
                    <div className="col-lg-12 mb-2 ">
                        {isCheckError && <p className="okc-error error-message">入力日を選択してください</p>}
                    </div>
                    <Label className="col-lg-2 mb-2 mb-lg-4" type="column" text={t('export_summary.input_date.title')} />
                    <div className="col-lg-4 mb-4">
                        <ReactCalender
                            classNameInputDateTime={`js-datepicker requestedDateMin
                                ${isCheckRequestedDateMin ? 'form-control--error' : ''}`}
                            onChange={(value) => handleChangeState('requestedDateMin', value)}
                            value={requestedDateMin}
                            maxDate={new Date()}
                            minDate={minRequestedDateMin()}
                        />
                    </div>
                    <div className="col-lg-2 justify-content-center mb-4">〜</div>
                    <div className="col-lg-4 mb-4">
                        <ReactCalender
                            classNameInputDateTime={`js-datepicker requestedDateMax
                                ${isCheckRequestedDateMax ? 'form-control--error' : ''}`}
                            onChange={(value) => handleChangeState('requestedDateMax', value)}
                            value={requestedDateMax}
                            maxDate={new Date()}
                            minDate={requestedDateMin ? new Date(requestedDateMin) : minRequestedDateMin()}
                        />
                    </div>
                    <div className="row no-gutters w-100 js-okc-validate">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-4">
                            {isCheckRequestedDateMin && (
                                <p className="ok-error mb-3 requested-date-min-error">{t('export_summary.requested_date_min_error')}</p>
                            )}
                        </div>
                        <div className="col-lg-2"></div>
                        <div className="col-lg-4">
                            {isCheckRequestedDateMax && (
                                <p className="ok-error mb-3 requested-date-max-error">{t('export_summary.requested_date_max_error')}</p>
                            )}
                        </div>
                    </div>
                    <Label
                        className="col-lg-2 mb-2 mb-lg-4"
                        type="column"
                        text={t('export_summary.division.title')}
                        style={{ height: '40px' }}
                    />
                    <div className="col-lg-4 mb-4" data-select2-id="select2-data-5-q2zy">
                        <SelectSearch
                            placeholder={t('export_summary.division_name.placeholder')}
                            isSearch={false}
                            onChange={(option) => {
                                handleChangeState('departmentCode', option.value);
                            }}
                            options={createDepartmentOptions(departments)}
                            value={departmentCode}
                        />
                    </div>
                    <div className="col-lg-6"></div>
                    <Label className="col-lg-2 mb-2 mb-lg-4" type="column" text={t('export_summary.competitive_chain.title')} />
                    <div className="col-lg-4 mb-4">
                        <MultiSelect
                            placeholder={t('export_summary.competitive_chain.placeholder')}
                            onChange={(option, codes) => {
                                handleChangeMultiState('competitorChainCode', option);
                                handleChangeMultiState('competitorChainCodes', codes);
                                handleChainChange(option, codes);
                            }}
                            options={createCompetitorChainOptions(competitorChains)}
                            value={competitorChainCode}
                        />
                    </div>
                    <Label
                        className="col-lg-2 mb-2 mb-lg-4 text-center competitor-store-name"
                        type="column"
                        text={t('export_summary.competitive_store.title')}
                        style={{ justifyContent: 'center' }}
                    />
                    <div className="col-lg-4 mb-4" data-select2-id="select2-data-62-9vnz">
                        <MultiSelect
                            placeholder={t('export_summary.competitive_store.placeholder')}
                            onChange={(option, codes) => {
                                handleChangeMultiState('competitorStoreCode', option);
                                handleChangeMultiState('competitorStoreCodes', codes);
                            }}
                            options={filteredChildOptions}
                            value={competitorStoreCode}
                            disabled={!competitorChainCode.length}
                        />
                    </div>
                    <Label className="col-lg-2 mb-2 mb-lg-4" type="column" text={t('export_summary.period_start.title')} />
                    <div className="col-lg-4 mb-4">
                        <ReactCalender
                            classNameInputDateTime="js-datepicker startDateMin"
                            onChange={(value) => handleChangeState('startDateMin', value)}
                            value={startDateMin}
                            maxDate={new Date(maxStartDateMin())}
                            minDate={new Date(minStartDateMin())}
                        />
                    </div>
                    <div className="col-lg-2 justify-content-center mb-4">〜</div>
                    <div className="col-lg-4 mb-4">
                        <ReactCalender
                            classNameInputDateTime="js-datepicker startDateMax"
                            onChange={(value) => handleChangeState('startDateMax', value)}
                            value={startDateMax}
                            maxDate={new Date(maxStartDateMin())}
                            minDate={startDateMin ? new Date(startDateMin) : new Date(minStartDateMin())}
                        />
                    </div>
                    <Label className="col-lg-2 mb-2 mb-lg-4" type="column" text={t('export_summary.period_end.title')} />
                    <div className="col-lg-4 mb-4">
                        <ReactCalender
                            classNameInputDateTime="js-datepicker endDateMin"
                            onChange={(value) => handleChangeState('endDateMin', value)}
                            value={endDateMin}
                            maxDate={new Date(maxEndDateMin(startDateMin))}
                            minDate={startDateMin ? new Date(startDateMin) : minRequestedDateMin()}
                        />
                    </div>
                    <div className="col-lg-2 justify-content-center mb-4">〜</div>
                    <div className="col-lg-4 mb-4">
                        <ReactCalender
                            classNameInputDateTime="js-datepicker endDateMax"
                            onChange={(value) => handleChangeState('endDateMax', value)}
                            value={endDateMax}
                            maxDate={new Date(maxEndDateMin(startDateMax))}
                            minDate={endDateMin ? new Date(endDateMin) : startDateMin ? new Date(startDateMin) : minRequestedDateMin()}
                        />
                    </div>
                </Card>
            </div>
        </div>
    );
};
export default ExportSummary;
