import { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../../assets/css/common/datepicker.css';
import { format, set } from 'date-fns';
import { ja } from 'date-fns/locale';

const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
};

const ReactCalender = (props) => {
    const {
        value,
        className,
        placeholder = 'YYYY/MM/DD',
        onChange,
        classNameInputDateTime = '',
        onBlur,
        style,
        minDate,
        maxDate,
        isWeekend,
    } = props;
    const customWeekdayLabels = ['日', '月', '火', '水', '木', '金', '土'];
    const [formattedDate, setFormattedDate] = useState(value);
    const [calendarVisible, setCalendarVisible] = useState(false);
    const calendarRef = useRef(null);
    const inputDatePickerRef = useRef(null);
    const [isTop, setTop] = useState(false);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleDateChange = (date) => {
        if (isValidDate(date)) {
            onChange(formatDate(date));
            setFormattedDate(formatDate(date));
        } else {
            onChange(null);
            setFormattedDate(null);
        }
        setCalendarVisible(false);
    };

    const handleOutFocus = (eventValue) => {
        if (isValidDate(eventValue)) {
            setFormattedDate(eventValue);
            onChange(eventValue);
        } else {
            if (eventValue.length >= 1 && eventValue.length < 9) {
                setFormattedDate(formatDate(new Date()));
                onChange(formatDate(new Date()));
            } else if ((eventValue.length >= 9 && eventValue.length < 10) || !eventValue) {
                setFormattedDate(null);
                onChange(null);
            }
        }
    };

    const isValidDate = (date) => {
        return date instanceof Date && !isNaN(date);
    };

    const parseDate = (str) => {
        const parts = str.split('/');
        if (parts.length === 3) {
            const year = parseInt(parts[0], 10);
            const month = parseInt(parts[1], 10) - 1;
            const day = parseInt(parts[2], 10);
            if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
                return new Date(year, month, day);
            }
        }
        return null;
    };

    const handleClickOutside = (event) => {
        if (calendarRef.current && !calendarRef.current.contains(event.target)) {
            setCalendarVisible(false);
        }
    };

    const disableDates = ({ date, view }) => {
        if (view === 'month') {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today;
        }
        return false;
    };

    const openCalenDar = () => {
        let scroll = document.getElementsByClassName('okc-right');
        let inputDatePicker = inputDatePickerRef.current;
        let heightSroll = scroll[0].clientHeight;
        let heightItemTop = inputDatePicker.getBoundingClientRect().top;

        if (heightSroll - heightItemTop < 270 || heightItemTop > 580) {
            setTop(true);
            setCalendarVisible(true);
        } else {
            setTop(false);
            setCalendarVisible(true);
        }
    };

    return (
        <div className="custom-date-picker">
            <input
                ref={inputDatePickerRef}
                className={`form-control form-control--input ${classNameInputDateTime}`}
                type="text"
                value={value ? value : ''}
                onFocus={() => openCalenDar()}
                placeholder={placeholder}
                onBlur={(event) => handleOutFocus(event.target.value)}
                onInput={(event) => handleDateChange(event.target.value)}
                style={{ ...style }}
            />
            {calendarVisible && (
                <div ref={calendarRef} className={`calendar-container ${className} ${isTop ? 'calendar-top' : ''}`}>
                    <Calendar
                        tileClassName="custom-background"
                        onChange={handleDateChange}
                        value={value}
                        formatShortWeekday={(locale, date) => customWeekdayLabels[date.getDay()]}
                        formatMonthYear={(locale, date) => format(date, 'yyyy年M月', { locale: ja })}
                        formatDay={(locale, date) => date.getDate()}
                        tileContent={({ date, view }) => {
                            if (view === 'year') {
                                return <div className="month-tile">{format(date, 'LLLL', { locale: ja })}</div>;
                            }

                            if (view === 'century') {
                                const year = date.getFullYear();
                                return <div className="century">{year}</div>;
                            }

                            return null;
                        }}
                        showFixedNumberOfWeeks={true}
                        goToRangeStartOnSelect={false}
                        allowPartialRange={true}
                        calendarType="gregory"
                        maxDate={maxDate}
                        minDate={minDate}
                        tileDisabled={({ date }) => isWeekend && ![5, 6, 0].includes(date.getDay())}
                    />
                </div>
            )}
        </div>
    );
};

export default ReactCalender;
