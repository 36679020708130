/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ChangeRequestResult = {
    id?: number;
    jan?: string;
    itemName?: string;
    imageUrl?: string;
    departmentCode?: number;
    lineCode?: number;
    classCode?: number;
    subclassCode?: number;
    lowerLimit?: number;
    competitorName?: string;
    lowestSellingPrice?: number;
    discountedLowestSellingPrice?: number;
    lowestPriceTypeName?: string;
    effectiveEndDate?: string;
    requestSellingPrice?: number;
    discountedRequestSellingPrice?: number;
    salesStartDate?: string;
    salesEndDate?: string;
    remandReason?: string;
    linkedItemFlag?: boolean;
    compensation?: string;
    stopPurchaseFlag?: string;
    saratokuSellingPrice?: number;
    edlpSellingPrice?: number;
    discountedEdlpSellingPrice?: number;
    expandSalesFloor?: ChangeRequestResult.expandSalesFloor;
    packingCategory?: ChangeRequestResult.packingCategory;
    saleType?: ChangeRequestResult.saleType;
    reportFlag?: boolean;
    freshnessReason?: string;
    otherSpecialReports?: string;
    taxRate?: number;
    capacityOfUnit?: number;
    quantityOfUnit?: number;
    discountedCategory?: string;
    prohibited?: boolean;
};
export namespace ChangeRequestResult {
    export enum expandSalesFloor {
        REGULAR = 'REGULAR',
        END = 'END',
        WAGON = 'WAGON',
        FLYER = 'FLYER',
    }
    export enum packingCategory {
        SINGLE_ITEM = 'SINGLE_ITEM',
        BALL = 'BALL',
        CASE = 'CASE',
        BUNDLE = 'BUNDLE',
        OTHER = 'OTHER',
    }
    export enum saleType {
        MONTHLY = 'MONTHLY',
        WEEKLY = 'WEEKLY',
        WEEKEND = 'WEEKEND',
        DAILY = 'DAILY',
        OTHER = 'OTHER',
    }
}

