import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditTable from '../../tables/EditTable';
import Tabs from '../../common/Tabs';
import Pagination from '../../common/Pagination';
import Card from '../../common/Card';
import Label from '../../common/Label';
import SelectSearch from '../../common/SelectSearch';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeRequestControllerService } from '../../../services';
import { fetchCompetitorChainsByStoreCode, fetchCompetitorStoresByStoreCode, fetchStores, setLoader } from '../../../redux/actions/api';
import ReactCalender from '../../common/Datepicker';
import Button from '../../common/Button';
import { createCompetitorChainOptions, createCompetitorStoreOptions, createStoreOptions } from '../../../utils/helpers/option';
import apiErrorHandler from '../../../api/apiErrorHandler';
import { getSellingPrice, isDeviated } from '../../../utils/helpers/formula';
import { useLocation } from 'react-router-dom';
import { clearErrors } from '../../../redux/actions/error';

const ChangeRequestBase = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    const { table, confirmStatus, apiResponse, setApiResponse, reload, confirmRequestId } = props;

    // 申請画面
    const isRequestingPage = confirmStatus === 'REQUESTING';
    // 承認画面
    // const isConfirmPage = confirmStatus === 'NOT_CONFIRM';
    // 差戻画面
    const isRemandPage = confirmStatus === 'REMAND';

    const constants = useSelector((state) => state.constants);
    const { stores, competitorStores, competitorChains } = constants;
    const storage = useSelector((state) => state.storage);
    const { isCompetitor, changeRequestType } = storage;
    const sort = useSelector((state) => state.sort);

    const [state, setState] = useState({
        page: 1,
        limit: 20,

        // 申請画面
        selectedCompetitorChainCode: '',
        selectedCompetitorStoreCode: '',
        selectedStoreCode: '',

        // 差戻画面
        requestedDate: null,

        isShowForm: false,
    });

    const {
        page,
        limit,
        selectedCompetitorChainCode,
        selectedCompetitorStoreCode,
        selectedStoreCode,

        requestedDate,

        isShowForm,
    } = state;

    useEffect(() => {
        dispatch(clearErrors());
        dispatch(fetchStores());
        dispatch(fetchCompetitorChainsByStoreCode());
        dispatch(fetchCompetitorStoresByStoreCode());
    }, [dispatch, location]);

    useEffect(() => {
        getChangeRequests();
    }, [changeRequestType, isShowForm, reload, page, sort]);

    const getChangeRequests = () => {
        dispatch(setLoader(true));
        dispatch(clearErrors());
        const request =
            (isCompetitor && isShowForm) || confirmRequestId
                ? ChangeRequestControllerService.getChangeRequestsNearbyStores(
                      confirmStatus,
                      selectedCompetitorChainCode ? selectedCompetitorChainCode : null,
                      selectedCompetitorStoreCode ? selectedCompetitorStoreCode : null,
                      selectedStoreCode ? selectedStoreCode : null,
                      confirmRequestId ? confirmRequestId : null,
                  )
                : ChangeRequestControllerService.getChangeRequests(
                      changeRequestType,
                      confirmStatus,
                      requestedDate ? requestedDate : null,
                      page,
                      limit,
                      table.heads.find((head) => head.field === sort.sortField)?.field,
                      sort.sortType,
                  );
        request
            .then(async (response) => {
                response.data = response.data.map((data) => {
                    return {
                        ...data,
                        selected: false, // 申請画面選択用
                        confirmed: false, // 承認画面選択用（承認）
                        remand: false, // 承認画面選択用（差戻）
                    };
                });
                setApiResponse(response);
                await getPromiseResult(response.data);
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const [discounts, setDiscounts] = useState([]);
    const getPromiseResult = async (responseData) => {
        const data = await discountCheck(responseData);
        setDiscounts(data);
    };

    const discountCheck = async (items) => {
        if (!items) {
            return [];
        }
        const results = await Promise.all(
            items.map(async (item) => {
                if (item.linkedItemFlag) {
                    return 'linkedItem';
                }
                if (!item.discountedRequestSellingPrice || !item.discountedCategory || !item.requestSellingPrice) {
                    return '';
                }

                const requestSellingPrice = item.requestSellingPrice;
                const saratokuSellingPrice = item.saratokuSellingPrice;
                const edlpSellingPrice = item.edlpSellingPrice;
                const comparePrice =
                    saratokuSellingPrice && saratokuSellingPrice < edlpSellingPrice ? saratokuSellingPrice : edlpSellingPrice;

                if (await isDeviated(requestSellingPrice, comparePrice, 30)) {
                    return '30%';
                }

                if (await isDeviated(requestSellingPrice, comparePrice, 10)) {
                    return '10%';
                }
                return '';
            }),
        );
        return results;
    };

    const renderTable = () => {
        return (
            <EditTable
                confirmStatus={confirmStatus}
                heads={table.heads}
                body={table.body}
                dataTable={apiResponse?.data}
                setDataTable={setDataTable}
                discounts={discounts}
            />
        );
    };

    const setDataTable = (data) => {
        setApiResponse({
            ...apiResponse,
            data,
        });
    };

    const handleShowForm = (value) => {
        setState((prevState) => ({
            ...prevState,
            isShowForm: value === 'mailConfirmTab',
            apiResponse: null,
            page: 1,
        }));
    };

    const handlePageClick = (event) => {
        handleChangeState('page', event.selected + 1);
    };

    const handleChangeState = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleClearForm = () => {
        setState((prevState) => ({
            ...prevState,
            selectedCompetitorChainCode: '',
            selectedCompetitorStoreCode: '',
            selectedStoreCode: '',
        }));
    };

    const handleSubmitForm = () => {
        getChangeRequests();
    };

    const getTabs = () => {
        if (confirmRequestId) {
            return [
                {
                    title: t('price_request.nearby_stores_tab'),
                    event: 'mailConfirmTab',
                    content: renderTable(),
                },
            ];
        }
        if (isCompetitor && !isRemandPage) {
            // 競合対抗かつ差戻でなければ近隣店舗連携データを表示
            return [
                {
                    title: t('price_request.price_registration_tab'),
                    event: 'confirmTab',
                    content: renderTable(),
                },
                {
                    title: t('price_request.nearby_stores_tab'),
                    event: 'mailConfirmTab',
                    content: renderTable(),
                },
            ];
        }
        return [
            {
                event: 'confirmTab',
                content: renderTable(),
            },
        ];
    };

    return (
        <>
            {isRequestingPage && isShowForm && (
                <Card handleSubmit={handleSubmitForm} handleClear={handleClearForm}>
                    <Label type="column" text={t('price_request.competitor_name.title')} className="col-lg-2 mb-2 mb-lg-4" />
                    <div className="col-lg-4 pr-lg-3 mb-4">
                        <SelectSearch
                            onChange={(option) => handleChangeState('selectedCompetitorChainCode', option.value)}
                            options={createCompetitorChainOptions(competitorChains)}
                            value={selectedCompetitorChainCode}
                            placeholder={t('price_request.competitor_name.placeholder')}
                        />
                    </div>
                    <Label type="column" text={t('price_request.competitor_store_name.title')} className="col-lg-2 pl-lg-3 mb-2 mb-lg-4" />
                    <div className="col-lg-4 pr-lg-3 mb-4">
                        <SelectSearch
                            onChange={(option) => handleChangeState('selectedCompetitorStoreCode', option.value)}
                            options={createCompetitorStoreOptions(competitorStores, selectedCompetitorChainCode)}
                            value={selectedCompetitorStoreCode}
                            disabled={!selectedCompetitorChainCode}
                            placeholder={t('price_request.competitor_store_name.placeholder')}
                        />
                    </div>
                    <Label type="column" text={t('price_request.oke_store_name.title')} className="col-lg-2 mb-2 mb-lg-4 fixed_height" />
                    <div className="col-lg-4 pr-lg-3 mb-4">
                        <SelectSearch
                            onChange={(option) => handleChangeState('selectedStoreCode', option.value)}
                            options={createStoreOptions(stores)}
                            value={selectedStoreCode}
                            placeholder={t('price_request.oke_store_name.placeholder')}
                        />
                    </div>
                </Card>
            )}

            {isRemandPage && (
                <div className="okc-card">
                    <Card type="modal_card">
                        <div className="row okc-card__row align-items-start">
                            <Label
                                type="column"
                                text={t('confirm_registration_price.remand.search_form.registration_date')}
                                className="col-lg-2 pl-lg-3"
                            />
                            <div className="col-lg-3">
                                <ReactCalender
                                    value={requestedDate}
                                    placeholder=""
                                    onChange={(value) => {
                                        handleChangeState('requestedDate', value);
                                    }}
                                />
                            </div>
                            <div className="col offset-lg-1">
                                <Button className="btn btn--primary" onClick={() => handleSubmitForm()}>
                                    <span className="btn-text">{t('confirm_registration_price.remand.search_form.search_button')}</span>
                                </Button>
                            </div>
                        </div>
                    </Card>
                </div>
            )}

            <div id="pagination" className="d-flex c-slide-top paginate-custom wp-pagination">
                <nav className="okc-pagination okc-pagination--total ml-auto">
                    <p className="okc-pagination__total">全{apiResponse?.totalCount}件</p>
                    <Pagination pageCount={apiResponse?.totalPage} onPageChange={handlePageClick} />
                </nav>
            </div>
            <div className="ok_tab_table">
                <Tabs type="material" tabs={getTabs()} handleClick={(event) => handleShowForm(event)} />
            </div>
        </>
    );
};

export default ChangeRequestBase;
