import { SET_SORT, SET_TYPE_ERROR, SET_TYPE_SUCCESS, SET_TYPE_WARNING } from './type';

export const setToastsSuccess = (message = '') => {
    return function (dispatch) {
        dispatch({
            type: SET_TYPE_SUCCESS,
            message,
        });
    };
};

export const setToastsWarning = (message = '') => {
    return function (dispatch) {
        dispatch({
            type: SET_TYPE_WARNING,
            message,
        });
    };
};

export const setToastsError = (message = '') => {
    return function (dispatch, getState) {
        const { errors } = getState().errors;
        if (!message || errors.includes(message)) {
            return;
        }

        dispatch({
            type: SET_TYPE_ERROR,
            message,
        });
    };
};

export const setSort = (
    sort = {
        sortField: null,
        sortType: 'ASC',
    },
) => {
    return function (dispatch) {
        dispatch({
            type: SET_SORT,
            sort,
        });
    };
};
