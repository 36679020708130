import { combineReducers } from 'redux';
import { loaderReducer } from './api';
import { constantsReducer } from './api';
import { sortReducer, toastReducer, typeRegisterReducer, typeSourceInformationReducer } from './common';
import { storageReducer } from './storage';
import { modalReducer } from './modal';
import { errorReducer } from './error';

const rootReducers = combineReducers({
    loader: loaderReducer,
    toasts: toastReducer,
    sort: sortReducer,
    register: typeRegisterReducer,
    sourceInfo: typeSourceInformationReducer,
    constants: constantsReducer,
    storage: storageReducer,
    modal: modalReducer,
    errors: errorReducer,
});

export default rootReducers;
