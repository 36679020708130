import React, { forwardRef, useEffect, useRef, useState } from 'react';
import '../../assets/css/common/table.css';
import '../../assets/css/common/common.css';
import { useDispatch, useSelector } from 'react-redux';
import ImageModal from '../common/ImageModal';
import { setSort } from '../../redux/actions/common';

const SelectTable = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { heads, body, dataTable = [], isSelectRow, isMultiSelectRow, onSelectRow, selectedRows } = props;

    const storage = useSelector((state) => state.storage);
    const { isCompetitor } = storage;

    const tableWrapperRef = useRef();

    const [showModal, setShowModal] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);

    const sort = useSelector((state) => state.sort);

    const handleOpenModal = (imageUrl) => {
        if (imageUrl) {
            setImageUrl(imageUrl);
            setShowModal(true);
        }
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSelectRow = (isSelectRow, index, record) => {
        if (!isSelectRow || !checkEnableSelect(record)) {
            return;
        }
        onSelectRow(index, record);
    };

    const renderHeads = () => {
        return (
            <tr>
                {heads?.map((head, index) => {
                    if (Object.keys(head).length === 0 || head.disabled) {
                        return <></>;
                    }

                    return (
                        <th
                            key={index}
                            colSpan={head.colSpan}
                            className={`v-select-default ${head.field ? 'sort' : ''} ${sort.sortField === head.field && sort.sortType === 'DESC' ? 'active' : ''} ${head.className}`}
                            onClick={() => {
                                if (!head.field) {
                                    return;
                                }
                                const sortType = sort.sortField === head.field ? (sort.sortType === 'DESC' ? 'ASC' : 'DESC') : 'ASC';
                                dispatch(
                                    setSort({
                                        sortField: head.field,
                                        sortType: sortType,
                                    }),
                                );
                            }}
                        >
                            <>{head?.title}</>
                        </th>
                    );
                })}
            </tr>
        );
    };

    const renderRowValue = (itemColumn, itemRow) => {
        let rowValue = null;
        const fields = itemColumn?.field?.split('.') ?? [];

        for (const field of fields) {
            rowValue = !rowValue ? itemRow[field ?? ''] : rowValue[field];
        }

        if (rowValue) {
            if (itemColumn?.mappingData) {
                return itemColumn?.mappingData.find((item) => item.value === rowValue)?.label ?? rowValue;
            }
            return rowValue;
        } else {
            return '-';
        }
    };

    const checkSelectedRow = (jan) => {
        let isSelectedRow = false;
        if (!selectedRows || selectedRows.length === 0) {
            return false;
        }
        selectedRows.forEach((itemRow) => {
            if (itemRow.jan === jan) {
                isSelectedRow = true;
            }
        });
        return isSelectedRow;
    };

    const checkEnableSelect = (itemRowSelect) => {
        if (itemRowSelect.prohibited) {
            return false;
        }
        if (!selectedRows || selectedRows.length === 0 || isMultiSelectRow) {
            return true;
        }
        const selectedRow = selectedRows[0];

        if (isCompetitor) {
            // 競合対抗は定価とユニット容量が同じ場合は複数選択可能
            return selectedRow.listPrice === itemRowSelect.listPrice && selectedRow.capacityOfUnit === itemRowSelect.capacityOfUnit;
        }
        return itemRowSelect.id === selectedRow.id;
    };

    const renderRows = () => {
        return (
            <>
                {dataTable?.length > 0 ? (
                    <>
                        {dataTable?.map((itemRow, indexRow) => (
                            <tr
                                key={indexRow}
                                className={`${isSelectRow ? 'v-select-row' : 'v-select-default'} ${
                                    isSelectRow && checkSelectedRow(itemRow.jan) ? 'selectable active' : ''
                                } ${checkEnableSelect(itemRow) ? '' : 'unselectable'} ${itemRow.prohibited ? 'v-back-gray' : ''}`}
                                onClick={() => {
                                    handleSelectRow(isSelectRow, indexRow, itemRow);
                                }}
                            >
                                {body?.columns
                                    ?.filter((item) => !!item)
                                    .map((itemColumn, indexColumn) => {
                                        if (Object.keys(itemColumn).length === 0) {
                                            return <td key={indexColumn}></td>;
                                        }
                                        return (
                                            <td
                                                key={indexColumn}
                                                className={`${itemColumn?.className ?? ''}`}
                                                onClick={(event) => {
                                                    if (itemColumn?.isLink && itemRow?.imageUrl) {
                                                        event.stopPropagation();
                                                        event.preventDefault();
                                                        handleOpenModal(itemRow?.imageUrl);
                                                    }
                                                }}
                                            >
                                                <span className={itemColumn?.isLink && itemRow?.imageUrl ? 'text-orange' : ''}>
                                                    {renderRowValue(itemColumn, itemRow)}
                                                </span>
                                            </td>
                                        );
                                    })}
                            </tr>
                        ))}
                    </>
                ) : (
                    <>
                        <tr className="no-result">
                            <td colSpan={heads?.length || 1}>該当商品が見つかりません。</td>
                        </tr>
                    </>
                )}
            </>
        );
    };

    return (
        <div>
            <ImageModal show={showModal} handleClose={handleCloseModal} imageUrl={imageUrl} />
            <div ref={tableWrapperRef} className={`okc-table components__table-wrapper bases__padding--bottom20`}>
                <div className="okc-table__wrapper table-responsive">
                    <table className="w-100">
                        <thead>{renderHeads()}</thead>
                        <tbody>{renderRows()}</tbody>
                    </table>
                </div>
            </div>
        </div>
    );
});

export default SelectTable;
