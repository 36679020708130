import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';

import Input from './Input';

const MultiSelect = (props) => {
    const { onChange, options, value, disabled, placeholder } = props;

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleOpen = () => {
        setIsOpen((prev) => !prev);
    };

    const onSelectChange = (values) => {
        const codes = values.map((item) => {
            return item.value;
        });

        toggleOpen();

        onChange(values, codes);
    };

    const Svg = (p) => <svg width="18" height="16" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />;

    const Chevron = ({ state }) => (
        <Svg style={{ marginLeft: 'auto' }}>
            <path d={isOpen ? 'M12 8l6 6H6z' : 'M12 16l-6-6h12z'} fill={isOpen ? '#d66f00' : 'currentColor'} />
        </Svg>
    );

    const DropdownIndicator = () => (
        <div>
            <span className="dropdown-indicator-triangle">
                {!disabled && (
                    <div className="icon_arrow">
                        <Chevron isOpen={isOpen} />
                    </div>
                )}
            </span>
        </div>
    );

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            borderRadius: '0.25rem',
            borderBottomRightRadius: state.isFocused ? '0px' : '',
            borderBottomLeftRadius: state.isFocused ? '0px' : '',
            borderColor: state.isFocused ? '#d66f00' : 'rgb(225, 230, 234)',
            boxShadow: null,
            minHeight: '40px',
            backgroundColor: state.isDisabled ? '#e9ecef' : '',
            ':hover': {
                borderColor: state.isFocused ? '#d66f00' : 'rgb(225, 230, 234)',
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#999',
            paddingLeft: '6px',
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#e9ecef',
            borderRadius: '0.2rem',
            color: '#495057',
            border: '1px solid #d66f00',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            padding: '2px 5px',
            color: '#d66f00',
            fontSize: '12px',
            fontWeight: '600',
            backgroundColor: 'white',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            backgroundColor: '#d66f00',
            color: 'white',
            ':hover': {
                backgroundColor: '',
                color: 'white',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#ef7a00' : state.isFocused ? 'rgba(239, 122, 0, 0.2)' : 'white',
            color: state.isSelected ? 'white' : state.isFocused ? '#ef7a00' : '#212529',
        }),
        menu: (provided) => ({
            ...provided,
            marginTop: 0,
            borderBottom: '1px solid #d66f00',
            borderLeft: '1px solid #d66f00',
            borderRight: '1px solid #d66f00',
            borderRadius: '',
            zIndex: 10,
        }),
        isDisabled: (provided) => ({
            ...provided,
            backgroundColor: '#e9ecef',
        }),
    };

    return (
        <div ref={dropdownRef} style={{ width: '100%' }} onClick={disabled ? undefined : toggleOpen}>
            <Select
                value={value}
                isDisabled={disabled}
                options={options}
                defaultValue={value}
                components={{ DropdownIndicator, IndicatorSeparator: null }}
                styles={customStyles}
                hideSelectedOptions={false}
                isClearable={false}
                placeholder={placeholder}
                onChange={(values) => {
                    toggleOpen();
                    onSelectChange(values);
                }}
                isMulti
            />
        </div>
    );
};

export default MultiSelect;
