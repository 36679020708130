export const getSearchTable = (t, isCompetitor) => {
    return {
        heads: [
            {
                title: t('price_registration.result_list.jan.title'),
                field: 'jan',
                className: 'v-base_minWidth--95 v-base_width--6',
            },
            {
                title: t('price_registration.result_list.commodity_classification.title'),
                className: 'v-base_minWidth--65 v-width-auto',
            },
            {
                title: t('price_registration.result_list.product_name.title'),
                field: 'itemName',
                className: 'v-base_minWidth--120 cell-border',
            },
            {
                title: t('price_registration.result_list.list_price.title'),
                className: 'text-center',
            },
            {
                title: t('price_registration.result_list.unit.title'),
                className: 'text-center cell-border',
                colSpan: 2,
            },
            {
                title: t('price_registration.result_list.lowest_selling_price.title'),
                className: 'text-center',
            },
            {
                title: t('price_registration.result_list.store_current_selling_price.title'),
                className: 'text-center',
            },
            {
                title: t('price_registration.result_list.store_current_unit_price.title'),
                className: 'text-center',
            },
            {
                title: t('price_registration.result_list.selling_price_type.title'),
                className: 'text-center',
            },
            {
                title: t('price_registration.result_list.end_date.title'),
                className: 'text-center cell-border',
            },
            isCompetitor && {
                title: t('price_registration.result_list.competitor_name.title'),
                className: 'text-center',
            },
            {
                title: t('price_registration.result_list.edlp_selling_price.title'),
                className: 'text-center',
            },
            {
                title: t('price_registration.result_list.store_standard_selling_price.title'),
                className: 'text-center',
            },
            {
                title: t('price_registration.result_list.store_standard_unit_price.title'),
                className: 'text-center cell-border',
            },
            isCompetitor && {
                title: t('price_registration.result_list.compensation.title'),
                className: 'v-base_minWidth--53 freeze-column',
            },
        ],
        body: {
            columns: [
                {
                    field: 'jan',
                },
                {
                    field: 'itemCategoryName',
                },
                {
                    field: 'itemName',
                    className: 'freeze-right-0 cell-border',
                    isLink: true,
                },
                {
                    field: 'listPrice',
                },
                {
                    field: 'capacityOfUnit',
                },
                {
                    field: 'unit',
                    className: 'freeze-right-0 cell-border',
                },
                {
                    field: 'lowestSellingPrice',
                },
                {
                    field: 'discountedLowestSellingPrice',
                },
                {
                    field: 'lowestSellingUnitPrice',
                },
                {
                    field: 'lowestPriceTypeName',
                },
                {
                    field: 'effectiveEndDate',
                    className: 'freeze-right-0 cell-border',
                },
                isCompetitor && {
                    field: 'competitorName',
                },
                {
                    field: 'edlpSellingPrice',
                },
                {
                    field: 'discountedEdlpSellingPrice',
                },
                {
                    field: 'edlpSellingUnitPrice',
                    className: 'freeze-right-0 cell-border',
                },
                isCompetitor && {
                    field: 'competitorFlag',
                },
            ],
        },
    };
};

export const getCompetitorRequestTable = (t, expandSalesFloors, packingCategories, saleTypes) => {
    return {
        heads: [
            {
                title: t('price_registration.registration_list_modal.jan'),
                field: 'jan',
                className: 'v-base_minWidth--95 v-base_width--6',
            },
            {
                title: t('price_registration.registration_list_modal.product_name.title'),
                field: 'itemName',
                className: 'v-base_minWidth--95 v-base_width--6',
            },
            {
                title: t('price_registration.registration_list_modal.sales_floor_development'),
                field: 'expandSalesFloor',
                className: 'text-center',
            },
            {
                title: t('price_registration.registration_list_modal.price.title'),
                field: 'discountedRequestSellingPrice',
                className: 'text-center',
            },
            {
                title: t('price_registration.registration_list_modal.sales_conditions'),
                field: 'packingCategory',
                className: 'text-center',
            },
            {
                title: t('price_registration.registration_list_modal.sale_type'),
                field: 'saleType',
                className: 'text-center',
            },
            {
                title: t('price_registration.registration_list_modal.start_date'),
                field: 'salesStartDate',
                className: 'text-center',
            },
            {
                title: t('price_registration.registration_list_modal.end_date'),
                field: 'salesEndDate',
                className: 'text-center',
            },
            {
                title: t('price_registration.registration_list_modal.other_noteworthy_things'),
                field: 'otherSpecialReports',
                className: 'text-center v-base_minWidth--220',
            },
        ],
        body: {
            columns: [
                {
                    field: 'jan',
                },
                {
                    field: 'itemName',
                },
                {
                    field: 'expandSalesFloor',
                    mappingData: expandSalesFloors,
                },
                {
                    field: 'discountedRequestSellingPrice',
                },
                {
                    field: 'packingCategory',
                    mappingData: packingCategories,
                },
                {
                    field: 'saleType',
                    mappingData: saleTypes,
                },
                {
                    field: 'salesStartDate',
                },
                {
                    field: 'salesEndDate',
                },
                {
                    field: 'otherSpecialReports',
                },
            ],
        },
    };
};

export const getFreshnessRequestTable = () => {
    return {
        heads: [
            {
                title: 'JAN',
                field: 'jan',
                className: 'v-base_minWidth--95 v-base_width--6',
            },
            {
                title: '商品名',
                field: 'itemName',
                className: 'v-base_minWidth--95 v-base_width--6',
            },
            {
                title: '鮮度起因値下げ',
                field: 'requestSellingPrice',
                className: 'text-center',
            },
            {
                title: '開始日',
                field: 'startDate',
                className: 'text-center',
            },
            {
                title: '終了日',
                field: 'endDate',
                className: 'text-center',
            },
            {
                title: '鮮度起因値下げ理由',
                field: 'freshnessReason',
                className: 'text-center',
            },
            {
                title: 'その他特記事項',
                field: 'otherSpecialReports',
                className: 'text-center v-base_minWidth--220',
            },
        ],
        body: {
            columns: [
                {
                    field: 'jan',
                },
                {
                    field: 'itemName',
                },
                {
                    field: 'discountedRequestSellingPrice',
                },
                {
                    field: 'salesStartDate',
                },
                {
                    field: 'salesEndDate',
                },
                {
                    field: 'freshnessReason',
                },
                {
                    field: 'otherSpecialReports',
                },
            ],
        },
    };
};

export const getExcelUploadTable = (expandSalesFloors, packingCategories, saleTypes) => {
    return {
        heads: [
            {
                title: 'JAN',
                field: 'jan',
                className: 'v-base_minWidth--95 v-base_width--6',
            },
            {
                title: '商品名',
                field: 'itemName',
                className: 'v-base_minWidth--95 v-base_width--6',
            },
            {
                title: '売場展開',
                field: 'expandSalesFloor',
                className: 'text-center',
            },
            {
                title: '競合店売価',
                field: 'discountedRequestSellingPrice',
                className: 'text-center',
            },
            {
                title: '販売方法・荷姿',
                field: 'packingCategory',
                className: 'text-center',
            },
            {
                title: '特売種別・期間',
                field: 'saleType',
                className: 'text-center',
            },
            {
                title: '開始日',
                field: 'salesStartDate',
                className: 'text-center',
            },
            {
                title: '終了日',
                field: 'salesEndDate',
                className: 'text-center',
            },
            {
                title: 'その他特記事項',
                field: 'otherSpecialReports',
                className: 'text-center',
            },
        ],
        body: {
            columns: [
                {
                    field: 'jan',
                },
                {
                    field: 'itemName',
                },
                {
                    field: 'expandSalesFloor',
                    mappingData: expandSalesFloors,
                },
                {
                    field: 'discountedRequestSellingPrice',
                },
                {
                    field: 'packingCategory',
                    mappingData: packingCategories,
                },
                {
                    field: 'saleType',
                    mappingData: saleTypes,
                },
                {
                    field: 'salesStartDate',
                },
                {
                    field: 'salesEndDate',
                },
                {
                    field: 'otherSpecialReports',
                },
            ],
        },
    };
};

export const getRequestingTable = (t, handleEditClick, isRequesting, isFreshness) => {
    return {
        heads: [
            {
                title: t('price_request.result_list.jan'),
                field: 'jan',
                className: 'v-base_minWidth--95 v-base_width--6',
            },
            {
                title: t('price_request.result_list.product_name'),
                field: 'itemName',
                className: 'v-base_minWidth--115 v-width-auto cell-border',
            },
            {
                title: t('price_request.result_list.competitor_store_name'),
                field: 'changeRequestCompetitorEntity.competitorCombinationMasterEntity.competitorStoreMasterEntity.competitorStoreName',
                className: 'v-base_minWidth--65 v-base_width--10',
                disabled: isFreshness,
            },
            {
                title: t('price_request.result_list.lowest_selling_price'),
                field: 'lowestSellingPrice',
                className: 'text-center v-base_minWidth--85 v-base_width--6',
            },
            {
                title: t('price_request.result_list.discounted_lowest_selling_price'),
                field: 'discountedLowestSellingPrice',
                className: 'v-base_minWidth--85 v-base_width--6',
            },
            {
                title: t('price_request.result_list.lowest_price_type'),
                field: 'lowestPriceType',
                className: 'v-base_minWidth--52 v-base_width--5',
            },
            {
                title: t('price_request.result_list.effective_end_date'),
                field: 'effectiveEndDate',
                className: 'v-base_minWidth--75 v-base_width--5 cell-border',
            },
            {
                title: isFreshness ? '鮮度起因値下げ\n（本体価格）' : t('price_request.result_list.discounted_competitor_selling_price'),
                field: 'requestSellingPrice',
                className: 'text-center v-base_minWidth--85 v-base_width--6',
            },
            {
                title: isFreshness ? '鮮度起因値下げ\n（会員価格）' : t('price_request.result_list.competitor_selling_price'),
                field: 'discountedRequestSellingPrice',
                className: 'v-base_minWidth--85 v-base_width--6',
            },
            {
                title: t('price_request.result_list.sales_start_date'),
                field: 'salesStartDate',
                className: 'v-base_minWidth--65 v-base_width--4',
            },
            {
                title: t('price_request.result_list.sales_end_date'),
                field: 'salesEndDate',
                className: 'v-base_minWidth--65 v-base_width--4 cell-border',
            },
            {
                title: t('price_request.result_list.compensation'),
                className: 'v-base_minWidth--75 v-base_width--6 cell-border',
                disabled: isFreshness,
            },
            {
                title: t('price_request.result_list.stop_purchase_flag'),
                className: 'v-base_minWidth--75 v-base_width--4',
                disabled: isFreshness,
            },
            {
                title: t('confirm_registration_price.remand.result_list.remand_reason'),
                className: 'v-base_minWidth--85 v-base_width--4',
                disabled: isRequesting,
            },
            {
                title: t('price_request.result_list.edit'),
                className: isFreshness ? '' : 'freeze-column freeze-right-53 cell-border-left v-base_width--3',
            },
            {
                title: t('price_request.result_list.select'),
                className: 'freeze-column freeze-right-0 cell-border-left',
            },
        ],
        body: {
            columns: [
                {
                    field: 'jan',
                },
                {
                    field: 'itemName',
                    className: 'cell-border',
                    isLink: true,
                },
                {
                    field: 'competitorName',
                    disabled: isFreshness,
                },
                {
                    field: 'lowestSellingPrice',
                },
                {
                    field: 'discountedLowestSellingPrice',
                },
                {
                    field: 'lowestPriceTypeName',
                },
                {
                    field: 'effectiveEndDate',
                    className: 'cell-border',
                },
                {
                    field: 'requestSellingPrice',
                },
                {
                    field: 'discountedRequestSellingPrice',
                },
                {
                    field: 'salesStartDate',
                },
                {
                    field: 'salesEndDate',
                    className: 'cell-border',
                },
                {
                    field: 'compensation',
                    className: 'cell-border',
                    disabled: isFreshness,
                },
                {
                    field: 'stopPurchaseFlag',
                    disabled: isFreshness,
                },
                {
                    field: 'remandReason',
                    disabled: isRequesting,
                },
                {
                    field: 'edit',
                    isButtonEdit: true,
                    className: isFreshness ? '' : 'edit-price freeze-column freeze-right-53 cell-border-left v-base_width--3',
                    onClick: (index, value) => {
                        handleEditClick(index, value);
                    },
                },
                {
                    field: 'selected',
                    isCheckbox: true,
                    className: 'freeze-column freeze-right-0 cell-border-left',
                },
            ],
        },
    };
};

export const getNotConfirmTable = (t, handleOnChange, isFreshness) => {
    return {
        heads: [
            {
                title: t('price_request.result_list.jan'),
                field: 'jan',
                className: 'v-base_minWidth--95 v-base_width--6',
            },
            {
                title: t('price_request.result_list.product_name'),
                field: 'itemName',
                className: 'v-base_minWidth--115 v-width-auto cell-border',
            },
            {
                title: t('price_request.result_list.competitor_store_name'),
                field: 'changeRequestCompetitorEntity.competitorCombinationMasterEntity.competitorStoreMasterEntity.competitorStoreName',
                className: 'v-base_minWidth--65 v-base_width--10',
                disabled: isFreshness,
            },
            {
                title: t('price_request.result_list.lowest_selling_price'),
                field: 'lowestSellingPrice',
                isSort: true,
                type: 'ASC',
                className: 'text-center v-base_minWidth--85 v-base_width--6',
            },
            {
                title: t('price_request.result_list.discounted_lowest_selling_price'),
                field: 'discountedLowestSellingPrice',
                isSort: true,
                type: 'ASC',
                className: 'v-base_minWidth--85 v-base_width--6',
            },
            {
                title: t('price_request.result_list.lowest_price_type'),
                field: 'lowestPriceType',
                isSort: true,
                type: 'ASC',
                className: 'v-base_minWidth--52 v-base_width--5',
            },
            {
                title: t('price_request.result_list.effective_end_date'),
                field: 'effectiveEndDate',
                isSort: true,
                type: 'ASC',
                className: 'v-base_minWidth--75 v-base_width--5 cell-border',
            },
            {
                title: isFreshness ? '鮮度起因値下げ\n（本体価格）' : t('price_request.result_list.discounted_competitor_selling_price'),
                field: 'requestSellingPrice',
                isSort: true,
                type: 'ASC',
                className: 'text-center v-base_minWidth--85 v-base_width--6',
            },
            {
                title: isFreshness ? '鮮度起因値下げ\n（会員価格）' : t('price_request.result_list.competitor_selling_price'),
                field: 'discountedRequestSellingPrice',
                isSort: true,
                type: 'ASC',
                className: 'v-base_minWidth--85 v-base_width--6',
            },
            {
                title: t('price_request.result_list.sales_start_date'),
                field: 'salesStartDate',
                isSort: true,
                type: 'ASC',
                className: 'v-base_minWidth--65 v-base_width--4',
            },
            {
                title: t('price_request.result_list.sales_end_date'),
                field: 'salesEndDate',
                isSort: true,
                type: 'ASC',
                className: 'v-base_minWidth--65 v-base_width--4 cell-border',
            },
            {
                title: t('price_request.result_list.compensation'),
                className: 'v-base_minWidth--75 v-base_width--6 cell-border',
                disabled: isFreshness,
            },
            {
                title: t('price_request.result_list.stop_purchase_flag'),
                className: 'v-base_minWidth--75 v-base_width--4',
                disabled: isFreshness,
            },
            {
                title: t('confirm_registration_price.result_list.determine'),
                className: 'v-base_minWidth--53 freeze-column cell-border-left',
            },
            {
                title: t('confirm_registration_price.result_list.remand_reason_flg'),
                className: 'v-base_minWidth--53 freeze-column cell-border-left',
            },
            {
                title: t('confirm_registration_price.result_list.remand_reason'),
                className: 'v-base_minWidth--80 freeze-column freeze-right-0 cell-border-left',
                type: 'textarea',
            },
        ],
        body: {
            columns: [
                {
                    field: 'jan',
                },
                {
                    field: 'itemName',
                    className: 'cell-border',
                },
                {
                    field: 'competitorName',
                    disabled: isFreshness,
                },
                {
                    field: 'lowestSellingPrice',
                },
                {
                    field: 'discountedLowestSellingPrice',
                },
                {
                    field: 'lowestPriceTypeName',
                },
                {
                    field: 'effectiveEndDate',
                    className: 'cell-border',
                },
                {
                    field: 'requestSellingPrice',
                },
                {
                    field: 'discountedRequestSellingPrice',
                },
                {
                    field: 'salesStartDate',
                },
                {
                    field: 'salesEndDate',
                    className: 'cell-border',
                },
                {
                    field: 'compensation',
                    className: 'cell-border',
                    disabled: isFreshness,
                },
                {
                    field: 'stopPurchaseFlag',
                    disabled: isFreshness,
                },
                {
                    field: 'confirmed',
                    isCheckbox: true,
                    className: 'freeze-column cell-border-left',
                },
                {
                    field: 'remand',
                    isCheckbox: true,
                    className: 'freeze-column freeze-right-80 cell-border-left',
                },
                {
                    field: 'remandReason',
                    isInput: true,
                    className: 'v-base_width--2 freeze-column freeze-right-0 cell-border-left',
                    maxLength: 30,
                    onChange: (id, value) => {
                        handleOnChange(id, value);
                    },
                },
            ],
        },
    };
};

export const getProhibitedTable = (t, isFreshness) => {
    return {
        heads: [
            {
                title: 'JAN',
                field: 'jan',
                className: 'v-base_minWidth--190 v-base_width--6',
            },
            {
                title: '商品名',
                field: 'itemName',
                className: 'v-base_minWidth--220',
            },
            {
                title: isFreshness ? '鮮度起因値下げ（本体価格）' : '競合店売価（本体価格）',
                field: 'discountedRequestSellingPrice',
                className: 'v-base_minWidth--160',
            },
            {
                title: isFreshness ? '鮮度起因値下げ（会員価格）' : '競合店売価（会員価格）',
                field: 'requestSellingPrice',
                className: 'v-base_minWidth--160',
            },
            {
                title: '下限値',
                field: 'competitor_selling_price',
                className: 'v-base_minWidth--220',
            },
        ],
        body: {
            columns: [
                {
                    field: 'origJan',
                },
                {
                    field: 'itemName',
                },
                {
                    field: 'discountedRequestSellingPrice',
                },
                {
                    field: 'requestSellingPrice',
                },
                {
                    field: 'lowerLimit',
                },
            ],
        },
    };
};

export const getExcelValidationTable = (t) => {
    return {
        heads: [
            {
                title: '行番号',
                field: 'rowNumber',
                className: 'v-base_minWidth--190 v-base_width--6',
            },
            {
                title: 'エラーメッセージ',
                field: 'message',
                className: 'v-base_minWidth--220',
            },
        ],
        body: {
            columns: [
                {
                    field: 'rowNumber',
                },
                {
                    field: 'message',
                },
            ],
        },
    };
};

export const getTableUpdateCompetitorMaster = (t) => {
    return {
        heads: [
            {
                title: t('update_competitor_master.table.head.requested_date'),
                field: 'createdDate',
                className: 'v-base_minWidth--93 v-base_width--6',
            },
            {
                title: t('update_competitor_master.table.head.store_name'),
                field: 'storeName',
                className: 'v-base_minWidth--130 v-base_width--8 cell-border',
            },
            {
                title: t('update_competitor_master.table.head.business_format'),
                field: 'businessFormat',
                className: 'v-base_minWidth--115 v-base_width--9',
            },
            {
                title: t('update_competitor_master.table.head.chain_name'),
                field: 'competitorChainName',
                className: 'v-base_minWidth--170 v-base_width--7',
            },
            {
                title: t('update_competitor_master.table.head.competitive_store_name'),
                field: 'competitorStoreName',
                className: 'v-base_minWidth--265 v-base_width--9',
            },
            {
                title: t('update_competitor_master.table.head.distance'),
                field: 'distanceName',
                className: 'v-base_minWidth--120 v-base_width--8',
            },
            {
                title: t('update_competitor_master.table.head.system_rank'),
                field: 'systemRankName',
                className: 'v-base_minWidth--100 v-base_width--5',
            },
            {
                title: t('update_competitor_master.table.head.store_rank'),
                field: 'storeRankName',
                className: 'v-base_minWidth--130 v-base_width--6',
            },
            {
                title: t('update_competitor_master.table.head.notification_flag'),
                className: 'freeze-column v-base_minWidth--52 v-base_width--6 cell-border-left freeze-right-0',
            },
        ],
        body: {
            columns: [
                {
                    field: 'createdDate',
                    className: 'freeze-column',
                },
                {
                    field: 'storeName',
                    className: 'cell-border freeze-column',
                },
                {
                    field: 'businessFormat',
                    className: 'freeze-column',
                },
                {
                    field: 'competitorChainName',
                    className: 'freeze-column',
                },
                {
                    field: 'competitorStoreName',
                    className: 'freeze-column',
                },
                {
                    field: 'distanceName',
                    className: 'freeze-column',
                },
                {
                    field: 'systemRankName',
                    className: 'freeze-column',
                },
                {
                    field: 'storeRankName',
                    className: 'freeze-column',
                },
                {
                    field: 'notificationFlag',
                    isCheckbox: true,
                    className: 'freeze-column freeze-right-0 cell-border-left',
                    checkboxDisabled: true,
                },
            ],
        },
    };
};

export const getAdminTable = (t, handleDeleteClick, handleEditClick) => {
    return {
        heads: [
            {
                title: t('user.label.authority'),
                field: 'authorityName',
                className: 'v-base_minWidth--12 v-base_width--3',
            },
            {
                title: t('user.label.organization'),
                field: 'organizationName',
                className: 'v-base_minWidth--12 v-base_width--2',
            },
            {
                title: t('user.label.store'),
                field: 'storeName',
                className: 'v-base_minWidth--60  v-base_width--4',
            },
            {
                title: t('user.label.account'),
                field: 'email',
                className: 'v-base_minWidth--93 v-base_width--12',
            },
            {
                title: t('user.label.last_login_at'),
                field: 'lastLoginAt',
                className: 'v-base_minWidth--40 v-base_width--5',
            },
            {
                title: t('user.label.edit'),
                className: 'v-base_minWidth--40 v-base_width--2',
            },
            {
                title: t('user.label.delete'),
                className: 'v-base_minWidth--40 v-base_width--2',
            },
        ],
        body: {
            columns: [
                {
                    field: 'authorityName',
                    className: 'freeze-column',
                },
                {
                    field: 'organizationName',
                    className: 'freeze-column',
                },
                {
                    field: 'storeName',
                    className: 'freeze-column',
                },
                {
                    field: 'email',
                    className: 'freeze-column',
                },
                {
                    field: 'lastLoginAt',
                    className: 'freeze-column',
                },
                {
                    field: 'confirmDeleteModal',
                    isButtonDelete: true,
                    label: 'remandReasonFlg',
                    className: 'freeze-column',
                    onClick: (index, value) => {
                        handleDeleteClick(index, value);
                    },
                },
                {
                    field: 'confirmDeleteModal',
                    isButtonEdit: true,
                    label: 'remandReasonFlg',
                    className: 'freeze-column',
                    onClick: (index, value) => {
                        handleEditClick(index, value);
                    },
                },
            ],
        },
    };
};

export const getProhibitedItemTable = (handleEditClick) => {
    return {
        heads: [
            {
                title: 'JAN',
                field: 'jan',
                className: 'v-base_minWidth--115 v-base_width--4',
            },
            {
                title: '商品名',
                field: 'itemName',
                className: 'v-base_minWidth--265 v-base_width--6',
            },
            {
                title: '部門',
                field: 'departmentName',
                className: 'v-base_minWidth--50 v-base_width--7',
            },
            {
                title: '本部マスタ価格',
                field: 'sellingPrice',
                className: 'v-base_minWidth--115 v-base_width--5',
            },
            {
                title: '下限値',
                field: 'lowerLimit',
                className: 'v-base_minWidth--50 v-base_width--8',
            },
            {
                title: '禁止',
                className: 'v-base_minWidth--30 v-base_width--5',
            },
            {
                title: '開始日',
                field: 'startDate',
                className: 'v-base_minWidth--50 v-base_width--6',
            },
            {
                title: '更新日',
                field: 'updatedAt',
                className: 'v-base_minWidth--80 v-base_width--7',
            },
            {
                title: '編集',
                className: 'v-base_minWidth--57 v-base_width--3 freeze-column freeze-right-0',
            },
        ],
        body: {
            columns: [
                {
                    field: 'jan',
                },
                {
                    field: 'itemName',
                },
                {
                    field: 'departmentName',
                },
                {
                    field: 'sellingPrice',
                },
                {
                    field: 'lowerLimit',
                },
                {
                    field: 'deleteFlag',
                    isCheckbox: true,
                    checkboxDisabled: true,
                },
                {
                    field: 'startDate',
                },
                {
                    field: 'updatedAt',
                },
                {
                    field: 'edit',
                    isButtonEdit: true,
                    className: 'freeze-column freeze-right-0',
                    onClick: (index, value) => {
                        handleEditClick(index, value);
                    },
                },
            ],
        },
    };
};

export const getExceptionItemTable = (t, handleDeleteClick) => {
    return {
        heads: [
            {
                title: t('exception_product_list.result_list.jan'),
                field: 'jan',
                className: 'v-base_minWidth--65 v-base_width--5',
            },
            {
                title: t('exception_product_list.result_list.product_name'),
                field: 'itemName',
                className: 'v-base_minWidth--65 v-base_width--15',
            },
            {
                title: t('exception_product_list.result_list.department'),
                field: 'departmentName',
                className: 'v-base_minWidth--65 v-base_width--4',
            },
            {
                title: t('exception_product_list.result_list.created_at'),
                field: 'createdAt',
                className: 'v-base_minWidth--65 v-base_width--3 cell-border',
            },
            {
                title: '削除',
                className: 'v-base_minWidth--65 v-base_width--2',
            },
        ],
        body: {
            columns: [
                {
                    field: 'jan',
                },
                {
                    field: 'itemName',
                },
                {
                    field: 'departmentName',
                },
                {
                    field: 'createdAt',
                    className: 'cell-border',
                },
                {
                    field: 'delete',
                    isButtonDelete: true,
                    onClick: (index, value) => {
                        handleDeleteClick(index, value);
                    },
                },
            ],
        },
    };
};

export const getProhibitedCategoryTable = () => {
    return {
        heads: [
            {
                title: '部門',
                field: 'departmentName',
                className: 'v-base_minWidth--12 v-base_width--3',
            },
            {
                title: 'ライン',
                field: 'lineName',
                className: 'v-base_minWidth--12 v-base_width--3',
            },
            {
                title: 'クラス',
                field: 'className',
                className: 'v-base_minWidth--12 v-base_width--3',
            },
            {
                title: 'サブクラス',
                field: 'subclassName',
                className: 'v-base_minWidth--12 v-base_width--3',
            },
        ],
        body: {
            columns: [
                {
                    field: 'departmentName',
                    className: 'freeze-column',
                },
                {
                    field: 'lineName',
                    className: 'freeze-column',
                },
                {
                    field: 'className',
                    className: 'freeze-column',
                },
                {
                    field: 'subclassName',
                    className: 'freeze-column',
                },
            ],
        },
    };
};

export const getTableRequestDissociatedTab = (t, handleDeleteClick, isItHeadquarter) => {
    return {
        heads: [
            {
                title: t('notification_registration.table.head.email'),
                field: 'email',
                className: 'freeze-column',
            },
            {
                title: t('notification_registration.table.head.department'),
                field: 'departmentName',
                className: 'freeze-column',
            },
            {
                title: t('notification_registration.table.head.registration_date'),
                field: 'createdAt',
                className: 'freeze-column',
            },
            {
                title: t('notification_registration.table.head.delete'),
                className: 'freeze-column',
                disabled: !isItHeadquarter,
            },
        ],
        body: {
            columns: [
                {
                    field: 'email',
                    className: 'freeze-column',
                },
                {
                    field: 'departmentName',
                    className: 'freeze-column',
                },
                {
                    field: 'createdAt',
                    className: 'freeze-column',
                },
                {
                    field: 'confirmDeleteModal',
                    isButtonDelete: true,
                    label: 'remandReasonFlg',
                    className: 'freeze-column',
                    onClick: (index, value) => {
                        handleDeleteClick(index, value);
                    },
                    disabled: !isItHeadquarter,
                },
            ],
        },
    };
};

export const getTableNearbyStoreTab = (t, handleDeleteClick, isItHeadquarter) => {
    return {
        heads: [
            {
                title: t('notification_registration.table.head.email'),
                field: 'email',
                className: 'freeze-column',
            },
            {
                title: t('notification_registration.table.head.registration_date'),
                field: 'createdAt',
                className: 'freeze-column',
            },
            {
                title: t('notification_registration.table.head.delete'),
                className: 'freeze-column',
                disabled: !isItHeadquarter,
            },
        ],
        body: {
            columns: [
                {
                    field: 'email',
                    className: 'freeze-column',
                },
                {
                    field: 'createdAt',
                    className: 'freeze-column',
                },
                {
                    field: 'confirmDeleteModal',
                    isButtonDelete: true,
                    label: 'remandReasonFlg',
                    className: 'freeze-column',
                    onClick: (index, value) => {
                        handleDeleteClick(index, value);
                    },
                    disabled: !isItHeadquarter,
                },
            ],
        },
    };
};

export const getTableFixedPointObservationTab = (t, handleDeleteClick, isItHeadquarter) => {
    return {
        heads: [
            {
                title: t('notification_registration.table.head.email'),
                field: 'email',
                className: 'freeze-column',
            },
            {
                title: t('notification_registration.table.head.registration_date'),
                field: 'createdAt',
                className: 'freeze-column',
            },
            {
                title: t('notification_registration.table.head.delete'),
                className: 'freeze-column',
                disabled: !isItHeadquarter,
            },
        ],
        body: {
            columns: [
                {
                    field: 'email',
                    className: 'freeze-column',
                },
                {
                    field: 'createdAt',
                    className: 'freeze-column',
                },
                {
                    field: 'confirmDeleteModal',
                    isButtonDelete: true,
                    label: 'remandReasonFlg',
                    className: 'freeze-column',
                    onClick: (index, value) => {
                        handleDeleteClick(index, value);
                    },
                    disabled: !isItHeadquarter,
                },
            ],
        },
    };
};

export const getTableChangeRequestApproved = (t, isHeadquarter) => {
    return {
        heads: [
            {
                title: t('approved_registration_price.result_list.status'),
                className: 'v-base_minWidth--65 v-base_width--6',
            },
            {
                title: t('approved_registration_price.result_list.tokubai_project_code'),
                className: 'v-base_minWidth--80 v-base_width--6',
            },
            {
                title: t('approved_registration_price.result_list.competitor_store_name'),
                className: 'v-base_minWidth--130 v-base_width--4',
            },
            {
                title: t('approved_registration_price.result_list.jan'),
                field: 'jan',
                className: 'v-base_minWidth--115 v-base_width--4',
            },
            {
                title: t('approved_registration_price.result_list.product_name'),
                field: 'itemName',
                className: 'v-base_minWidth--220 v-base_width--4',
            },
            {
                title: t('approved_registration_price.result_list.current_selling_price'),
                className: 'v-base_minWidth--90 v-base_width--5',
            },
            {
                title: t('approved_registration_price.result_list.selling_price'),
                className: 'v-base_minWidth--90 v-base_width--5',
            },
            {
                title: t('approved_registration_price.result_list.start_date'),
                className: 'text-center v-base_minWidth--85 v-base_width--4',
            },
            {
                title: t('approved_registration_price.result_list.end_date'),
                className: 'v-base_minWidth--85 v-base_width--4',
            },
            ...(!isHeadquarter
                ? [
                      {
                          title: t('approved_registration_price.result_list.delete'),
                          className: 'text-center v-base_minWidth--65 v-base_width--2 freeze-column freeze-right-0 cell-border-left',
                      },
                  ]
                : []),
        ],
        body: {
            columns: [
                {
                    field: 'status',
                },
                {
                    field: 'tokubaiProjectCode',
                },
                {
                    field: 'competitorName',
                },
                {
                    field: 'jan',
                },
                {
                    field: 'itemName',
                },
                {
                    field: 'sellingPrice',
                },
                {
                    field: 'competitorSellingPrice',
                },
                {
                    field: 'startDate',
                },
                {
                    field: 'endDate',
                },
                ...(!isHeadquarter
                    ? [
                          {
                              field: 'selected',
                              isCheckbox: true,
                              className: 'freeze-column freeze-right-0 cell-border-left',
                          },
                      ]
                    : []),
            ],
        },
    };
};
