export const SET_LOADER = 'set_loader';
export const SET_TYPE_WARNING = 'set_warning';
export const SET_TYPE_ERROR = 'set_error';
export const CLEAR_ERRORS = 'clear_error';
export const SET_TYPE_SUCCESS = 'set_success';
export const SET_TYPE_NO_EXCEL = 'no excel';
export const SET_TYPE_EXCEL = 'excel';
export const SET_TYPE_FLYER = 'チラシ';
export const SET_TYPE_STORE = '店舗';

export const SET_SORT = 'set_sort';

export const CHANGE_REQUEST_TYPE = 'change_request_type';
export const ORGANIZATION = 'organization';
export const AUTHORITY = 'authority';

export const SELECTED_COMPETITOR_STORE_CODE = 'selected_competitor_store_code';
export const REQUESTER_NAME = 'requester_name';
export const SELECTED_SOURCE = 'selected_source';
export const SELECTED_REGISTRATION_METHOD = 'selected_registration_method';
export const SELECTED_DEPARTMENTS = 'selected_departments';

export const FETCH_SOURCES = 'fetch_sources';
export const FETCH_REGISTRATION_METHODS = 'fetch_registration_methods';
export const FETCH_DEPARTMENTS = 'fetch_departments';
export const FETCH_DIVISIONS = 'fetch_divisions';
export const FETCH_STORES = 'fetch_stores';
export const FETCH_COMPETITOR_CHAINS = 'fetch_competitor_chains';
export const FETCH_COMPETITOR_STORES = 'fetch_competitor_stores';

export const FETCH_ITEM_CATEGORIES = 'fetch_item_categories';
export const FETCH_EXPAND_SALES_FLOORS = 'fetch_expand_sales_floors';
export const FETCH_PACKING_CATEGORIES = 'fetch_packing_categories';
export const FETCH_SALE_TYPES = 'fetch_sale_types';
export const FETCH_DISTANCES = 'fetch_distances';
export const FETCH_FREQUENCY_RANKS = 'fetch_frequency_ranks';
export const FETCH_BUSINESS_FORMAT = 'fetch_business_format';
export const FETCH_USER_ORGANIZATION = 'fetch_user_organization';

export const IS_CONTINUE = 'is_continue';
export const FIFTY_DISCOUNT = 'fifty_discount';
export const THIRTY_DISCOUNT = 'thirty_discount';
export const TEN_DISCOUNT = 'ten_discount';
export const LIMIT_OVER = 'limit_over';
export const PROHIBITED = 'prohibited';
export const EXCEL_VALIDATION = 'excel_validation';
export const IS_DELETE_CONTINUE = 'is_delete_continue';
export const DELETE_CONFIRM = 'delete_confirm';
export const COMMON = 'common';
