import { useEffect, useState } from 'react';
import Card from './../../common/Card';
import { useTranslation } from 'react-i18next';
import Choice from '../../common/Choice';
import Input from '../../common/Input';
import '../../../assets/css/pages/stores/upload-file.css';
import Button from '../../common/Button';
import Dropzone from '../../common/Dropzone';
import { TAX_INCLUDED_TYPE } from '../../../utils/constants/string';
import { useLocation, useNavigate } from 'react-router-dom';
import { Workbook } from 'exceljs';
import {
    fetchExpandSalesFloors,
    fetchItemCategories,
    fetchPackingCategories,
    fetchSaleTypes,
    fetchSources,
    setLoader,
} from '../../../redux/actions/api';
import { useDispatch, useSelector } from 'react-redux';
import { limitOverValidation, prohibitedValidation, validateExcelInput } from '../../../utils/helpers/validation';
import { ChangeRequestControllerService, PriceSearchControllerService } from '../../../services';
import { setToastsError, setToastsSuccess } from '../../../redux/actions/common';
import { setExcelValidation } from '../../../redux/actions/modal';
import ViewTable from '../../tables/ViewTable';
import { getExcelUploadTable } from '../../tables/table';
import { getPercentagePrice, getTaxExcludedPrice } from '../../../utils/helpers/formula';
import apiErrorHandler from '../../../api/apiErrorHandler';
import { clearErrors } from '../../../redux/actions/error';

const DEFAULT_STATE = {
    radioChecked: 'excluded',
    discountRate: 0,
    file: '',
    resultData: [],
};

const UploadExcel = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useNavigate();
    const location = useLocation();

    const [state, setState] = useState(DEFAULT_STATE);
    const { radioChecked, discountRate, file, resultData } = state;

    const [formData, setFormData] = useState(null);

    const constants = useSelector((state) => state.constants);
    const { expandSalesFloors, packingCategories, saleTypes, sources } = constants;

    const table = getExcelUploadTable(expandSalesFloors, packingCategories, saleTypes);

    useEffect(() => {
        dispatch(clearErrors());
        dispatch(fetchItemCategories());
        dispatch(fetchExpandSalesFloors());
        dispatch(fetchPackingCategories());
        dispatch(fetchSaleTypes());
        dispatch(fetchSources());
    }, [dispatch, location]);

    const handleChangeInput = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleClearForm = () => {
        setState(DEFAULT_STATE);
    };

    const handleComplete = () => {
        history('/change-request/requesting');
    };

    useEffect(() => {
        if (file) {
            const readExcelFile = async () => {
                const workbook = new Workbook();
                const buffer = await file.arrayBuffer();
                await workbook.xlsx.load(buffer);
                const formData = [];

                workbook.eachSheet((sheet, id) => {
                    if (id === 1) {
                        sheet.eachRow((row, rowNumber) => {
                            if (rowNumber > 1) {
                                formData.push({
                                    id: rowNumber,
                                    jan: row.values[1],
                                    expandSalesFloor: expandSalesFloors.find((item) => item.label === row.values[6])?.value,
                                    discountedRequestSellingPrice: row.values[7],
                                    packingCategory: packingCategories.find((item) => item.label === row.values[8])?.value,
                                    saleType: saleTypes.find((item) => item.label === row.values[9])?.value,
                                    salesStartDate: row.values[10],
                                    salesEndDate: row.values[11],
                                    otherSpecialReports: row.values[12],
                                    competitorStoreCode: row.values[13],
                                    requesterName: row.values[14],
                                    source: sources.find((item) => item.label === row.values[15])?.value,
                                });
                            }
                        });
                    }
                });

                setFormData(formData);
            };

            readExcelFile();
        }
    }, [file]);

    const handleUpload = () => {
        if (validateExcelInput(dispatch, formData)) {
            return;
        }

        dispatch(setLoader(true));
        dispatch(clearErrors());
        PriceSearchControllerService.search({
            jans: formData.map((item) => item.jan),
            changeRequestType: 'COMPETITOR',
            limit: formData.length,
        })
            .then(handleSubmit)
            .catch((error) => {
                apiErrorHandler(dispatch, error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleSubmit = (apiResponse) => {
        const requestData = [];
        const errors = [];
        const data = apiResponse.data;
        dispatch(clearErrors());
        formData.forEach((row) => {
            const item = data.find((item) => item.jan === row.jan);
            if (item) {
                // 商品情報をマージ
                const request = {
                    ...row,
                    ...item,
                };

                // 税込の場合、税抜価格を計算
                if (radioChecked === 'included') {
                    request.discountedRequestSellingPrice = getTaxExcludedPrice(request.discountedRequestSellingPrice, request.taxRate);
                }

                // 割引率が設定されている場合、割引価格を計算
                if (discountRate > 0) {
                    request.discountedRequestSellingPrice = getPercentagePrice(request.discountedRequestSellingPrice, discountRate);
                }

                requestData.push(request);
            } else {
                errors.push({
                    rowNumber: row.id,
                    message: '存在しない商品です。',
                });
            }
        });

        if (errors.length > 0) {
            setExcelValidation({
                isShow: true,
                errors,
            });
            return;
        }

        // 下限値超過チェック
        if (limitOverValidation(dispatch, requestData)) {
            return;
        }

        // 禁止商品チェック
        if (prohibitedValidation(dispatch, requestData)) {
            return;
        }

        // 登録処理
        ChangeRequestControllerService.createChangeRequestCompetitor('EXCEL', requestData)
            .then(() => {
                dispatch(setToastsSuccess('登録が完了しました。'));
                handleClearForm();
                setState({
                    ...DEFAULT_STATE,
                    resultData: requestData,
                });
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error, t('message.error.registration'));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    return (
        <div className="upload-registration">
            <div className="okc-inner">
                <div className="okc-card fade-in">
                    <Card type="modal_card">
                        <div className="row okc-card__row mb-0">
                            <label className="col-lg-2 mt-lg-2 align-self-start">{t('upload_registration.select_price_type.title')}</label>
                            <div className="col-lg-10 competitive-store__radios">
                                <div className="row m-0 competitive-store__row js-okc-validate">
                                    <Choice
                                        column="col-xl-3 col-lg-4 col-md-5"
                                        type="radio"
                                        data={TAX_INCLUDED_TYPE}
                                        onChange={(value) =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                radioChecked: value[0],
                                            }))
                                        }
                                        checked={[radioChecked]}
                                        value={radioChecked}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row okc-card__row mb-0">
                            <label className="col-lg-2 mt-lg-2 align-self-start">{t('upload_registration.discount_rate.title')}</label>
                            <div className="col-lg-10">
                                <div className="m-0 discount-rate">
                                    <Input
                                        className="form-control form-control--input"
                                        value={discountRate}
                                        onChange={(value) => handleChangeInput('discountRate', value)}
                                        id="discountRate"
                                        type="text"
                                        maxLength="3"
                                        autoComplete="off"
                                    />
                                    <span>{t('upload_registration.discount_rate.percent')}</span>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="okc-title c-slide-top">{t('upload_registration.box.select_file.label')}</div>
                <div className="okc-input-file c-slide-top">
                    <div className="okc-upload">
                        <Dropzone file={file} onChange={(value) => handleChangeInput('file', value)} />
                        <p className="okc-error mt-2 mb-0 d-none"></p>
                    </div>
                </div>
                <div className="d-flex justify-content-end c-slide-top mb-5">
                    <Button className="btn btn--primary upload-registration__submit" onClick={handleUpload} disabled={!file}>
                        <span className="btn-text">{t('button.upload')}</span>
                    </Button>
                    <Button className="btn btn--secondary ml-2" id="btn_clear" onClick={handleClearForm}>
                        <span className="btn-text">{t('button.clear')}</span>
                    </Button>
                    <Button className="btn btn--primary btn-block ml-2" onClick={handleComplete}>
                        <span className="btn-text">{t('button.completion')}</span>
                    </Button>
                </div>
                {resultData.length !== 0 && <ViewTable heads={table.heads} body={table.body} dataTable={resultData} />}
            </div>
        </div>
    );
};
export default UploadExcel;
