export const minRequestedDateMin = () => {
    const today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth();
    month -= 3;

    // 月が負の場合は前年に補正
    if (month < 0) {
        month += 12;
        year -= 1;
    }
    return new Date(year, month, today.getDate());
};

export const minStartDateMin = () => {
    let minStartDateMin = new Date();
    return minStartDateMin.setDate(new Date().getDate() - 14);
};

export const maxStartDateMin = () => {
    let maxStartDateMin = new Date();
    return maxStartDateMin.setDate(new Date().getDate() + 14);
};

export const maxEndDateMin = (timestamp) => {
    let maxEndDateMin = timestamp ? new Date(timestamp) : new Date();

    return maxEndDateMin.setDate(maxEndDateMin.getDate() + 30);
};

export const formattedDate = (timestamp) => {
    let date = new Date(timestamp);
    return date.getFullYear() + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2);
};
