import { setToastsError } from '../redux/actions/common';

const apiErrorHandler = (dispatch, error, disconnectMessage) => {
    const errorMessage = !error.response
        ? (disconnectMessage ?? '通信が失敗しました。再度実行をお願いします。')
        : error.status === 401
          ? '認証エラー'
          : error.body.message;

    switch (error.status) {
        case 401:
            window.location.href = '/login';
            break;
        default:
            dispatch(setToastsError(errorMessage));
            break;
    }
};

export default apiErrorHandler;
